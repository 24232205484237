<ng-container *ngIf="!this.company?.billing.billingActive">
  <nz-alert
    nzType="success"
    nzMessage="Hinweis"
    nzDescription="Während der Testphase entstehen Ihnen natürlich keine Kosten!"
    nzShowIcon
    class="m-b-10"
  ></nz-alert>
</ng-container>

<nz-card>
  <h2>Abrechnungsintervall</h2>
  <p>Wählen Sie ein Abrechnungsintervall</p>
  <nz-spin [nzSpinning]="loading">

    <div>
      <div>
        <div class="select-item p-v-20" [ngClass]="{ 'active' : renewIntervalId === interval.id }"
             (click)="select(interval)" *ngFor="let interval of intervals">

          <div class="inline-block">
            <label>{{ interval.name }}</label>
            <nz-tag class="m-l-10" [nzColor]="'blue'">{{ interval.netto }} €</nz-tag>
            <p>{{ interval.description }}</p>
          </div>

        </div>

      </div>

    </div>




    <small>Alle Preise zzgl. gesetzliche MwSt.</small>
  </nz-spin>
</nz-card>

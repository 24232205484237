<div>
    <h2>Arbeitsprotokoll erstellen</h2>
    <nz-divider></nz-divider>

    <ng-container *ngIf="worklog.ticket">
        <p class="color-red">
            <i nz-icon nzType="info-circle" nzTheme="outline" class="m-r-5"></i><small>Dieses Arbeitsprotokoll wird mit
            diesem Ticket <strong>{{worklog.ticket.ticketNumber}}</strong> verknüpft.</small>
        </p>
    </ng-container>

    <form nz-form *ngIf="worklogForm" [formGroup]="worklogForm">
        <div nz-row>
            <nz-form-item nz-col nzSpan="12" class="p-r-5">
                <nz-form-label nzRequired nzFor="description">Start</nz-form-label>
                <nz-form-control>
                    <nz-date-picker
                            style="width: 100%"
                            [nzShowTime]="{ nzFormat: 'HH:mm' }"
                            nzFormat="dd.MM.yyyy HH:mm"
                            nzPlaceHolder="Bitte setzen Sie eine Startzeit"
                            formControlName="start"
                    ></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item nz-col nzSpan="12">
                <nz-form-label nzFor="description">Ende</nz-form-label>
                <nz-form-control>
                    <nz-date-picker
                            style="width: 100%"
                            [nzShowTime]="{ nzFormat: 'HH:mm' }"
                            nzFormat="dd.MM.yyyy HH:mm"
                            nzPlaceHolder="Setzen Sie eine Endzeit (optional)"
                            formControlName="end"
                    ></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-row>
            <nz-form-item nz-col nzSpan="24">
                <nz-form-label nzFor="description">Tätigkeit</nz-form-label>
                <nz-form-control>
                    <textarea formControlName="description" nz-input placeholder="Tätigkeit"></textarea>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-row>

            <nz-form-item nz-col nzSpan="12">
                <app-object-customer-select #referenceSelect [worklog]="worklog" [roomSelect]="true" [(room)]="worklog.room"
                                            [multiple]="false"
                                            [disabled]="worklog.finalized" [(reference)]="reference"
                                            (referenceChange)="referenceSelected($event)"></app-object-customer-select>
            </nz-form-item>


            <nz-form-item nz-col nzSpan="12" class="p-l-5">
                <nz-form-label nzRequired nzFor="creator">Ersteller</nz-form-label>
                <app-user-select [allowClear]="false" #userSelectComponent [(selectedUsers)]="worklog.creator"
                                 (selectedUsersChange)="checkUser($event)"></app-user-select>
            </nz-form-item>
        </div>
        <nz-alert class="m-b-10" *ngIf="worklogForm?.errors" nzType="error" [nzMessage]="worklogForm?.errors?.dates"
                  nzShowIcon></nz-alert>
    </form>

    <div nz-row *ngIf="!worklog.finalized" class="m-t-10">
        <div nz-col nzSpan="24">
            <button class="m-r-10" nzSize="small" nz-button nzType="default" (click)="cancel()" type="button">
                Abbrechen
            </button>

            <button nz-button nzType="primary" class="pull-right" nzSize="small" type="submit" (click)="save()"
                    [disabled]="worklogForm?.invalid">Speichern
            </button>
        </div>
    </div>
</div>

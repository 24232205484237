import { Injectable } from '@angular/core';
import {NewWorklogComponent, ModalData} from "../components/new-worklog/new-worklog.component";
import {NzModalService} from "ng-zorro-antd/modal";
import {WorkLog} from "../../../../../database-models";

@Injectable({
  providedIn: 'root'
})
export class WorklogService {

  constructor(
    private modalService: NzModalService
  ) {
  }

  public newWorklog(worklog?: WorkLog): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const newModal = this.modalService.create<NewWorklogComponent, ModalData>({
        nzContent: NewWorklogComponent,
        nzData: {
        worklog,
      },
        nzWidth: '1000px',
        nzFooter: null,
        nzKeyboard: false
      });
      newModal.afterClose.subscribe((data: any) => {
        if (data?.reason === 'success') {
          resolve(data.data);
        } else {
          reject(data)
        }
      });
    })
  }
}

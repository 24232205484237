<div nz-row>
  <div nz-col nzSpan="13" *ngIf="user">
    <h2>{{ user.name }}</h2>
  </div>
</div>
<div nz-row nzGutter="16">
  <div nz-col nzSpan="16">
    <nz-card *ngIf="user">
      <nz-spin [nzSpinning]="userLoading">

        <nz-tabset nzLinkRouter [nzCanDeactivate]="canDeactivate" class="page-tab" nzSize="small">
          <nz-tab>
            <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tab: 'general' }"
               queryParamsHandling="merge">
              <i nz-icon nzType="info-circle" nzTheme="outline"></i> Allgemein <i *ngIf="userForm?.invalid"
                                                                                  class="color-red m-l-5" nz-icon
                                                                                  nzType="exclamation-circle"
                                                                                  nzTheme="fill"></i>
            </a>
            <form nz-form nzLayout="vertical" [formGroup]="userForm" (ngSubmit)="updateUser()">
              <h3 class="m-b-20">
                Mitarbeiter-Daten
              </h3>

              <nz-form-item>
                <nz-form-label nzFor="name" nzRequired>Vorname, Nachname</nz-form-label>
                <nz-form-control nzErrorTip="Vor- und Nachname angeben (min. drei Zeichen)">
                  <input formControlName="name" nz-input placeholder="Vor- und Nachname">
                </nz-form-control>
              </nz-form-item>

              <nz-row nzGutter="16">
                <div nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label nzFor="email" nzRequired>E-Mailadresse</nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie eine gültige E-Mailadresse an">
                      <input formControlName="email" type="email" nz-input placeholder="mustermann@musterfirma.de">
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label nzFor="username" nzRequired>Benutzername</nz-form-label>
                    <nz-form-control
                      nzErrorTip="Bitte geben Sie einen gültigen Benutzernamen an. Dieser muss mindestens aus 5 Zeichen bestehen und darf keine Leerzeichen beinhalten.">
                      <input (focus)="setUsername()" formControlName="username" nz-input placeholder="mustermann123">
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </nz-row>

              <nz-row nzGutter="16">
                <div nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label nzFor="phone">Telefonnummer</nz-form-label>
                    <nz-form-control>
                      <input formControlName="phone" nz-input placeholder="Telefonnummer">
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </nz-row>

              <nz-row nzGutter="16">
                <div nz-col nzSpan="16">
                  <nz-form-item>
                    <nz-form-control>
                      <nz-form-label>Teams</nz-form-label>
                      <nz-select formControlName="userTeams"
                                 nzMode="multiple"
                                 nzPlaceHolder="Teams"
                                 [compareWith]="compareById"
                      >
                        <nz-option *ngFor="let item of teams" [nzLabel]="item.name" [nzValue]="item"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzSpan="8">
                  <nz-form-item>
                    <nz-form-label>Farbe</nz-form-label>
                    <nz-form-control>

                      <input nz-input [(colorPicker)]="user.color" [style.background]="user.color"/>
                    </nz-form-control>
                  </nz-form-item>

                </div>
              </nz-row>

              <nz-row nzGutter="16">
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-control>
                      <nz-form-label>Rolle des Mitarbeiters</nz-form-label>
                      <br>
                      <nz-radio-group formControlName="userRole"
                                      [nzDisabled]="user.id === authService.currentUserValue.id">
                        <label nz-radio [nzValue]="'employee'" nz-tooltip
                               nzTooltipTitle="Diese Rolle erlaubt dem Mitarbeiter
                      das Einloggen in die App mit allen Funktionen">Mitarbeiter</label>
                        <label nz-radio [nzValue]="'manager'" nz-tooltip
                               nzTooltipTitle="Diese Rolle erlaubt dem Mitarbeiter
                      das Einloggen in die App und in die Weboberfläche, die Verwaltung von Tickets, News, Objekte und
                      Hausverwaltungen">Manager</label>
                        <label nz-radio [nzValue]="'admin'" nz-tooltip nzTooltipTitle="Diese Rolle gewährt dem Mitarbeiter
                      alle Berechtigungen eines Managers und darüber hinaus die Verwaltung von Personal und Account">Administrator</label>
                      </nz-radio-group>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-control>
                      <nz-form-label>Benachrichtigungen</nz-form-label>
                      <br>
                      <label nz-checkbox nz-tooltip nzTooltipTitle="Bei jedem Ticket, das neu erstellt wird, egal ob von einem anderen Mitarbeiter oder Bewohner, wird dieser
                    Mitarbeiter benachrichtigt. Darüber hinaus, erhält man über alle Updates des Tickets
                    Benachrichtigungen. Diese Einstellung ist daher nicht für jeden Benutzer sinnvoll. Vor allem aber für Manager oder Administratoren, kann diese Option Vorteile schaffen."
                             formControlName="ticket_contributor">
                        Benachrichtigungen automatisch für neu erstellte Tickets aktivieren

                      </label>
                      <!-- <br>
                       <label nz-checkbox formControlName="emailNotification">E-Mail Benachrichtigung bei jeder änderung an einem Ticket</label>-->
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </nz-row>
              <div class="text-right m-b-20">
                <button nz-button nzType="primary" type="submit"
                        [disabled]="userForm.invalid || timeTrackingForm.invalid || !user.renew_interval_id">
                  Speichern
                </button>
              </div>
            </form>
          </nz-tab>

          <nz-tab [nzDisabled]="!user.id">
            <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tab: 'personalFile' }"
               queryParamsHandling="merge">
              <i nz-icon nzType="folder-open" nzTheme="outline"></i> Personalakte <i *ngIf="personalForm?.invalid"
                                                                                     class="color-red m-l-5" nz-icon
                                                                                     nzType="exclamation-circle"
                                                                                     nzTheme="fill"></i>
            </a>
            <div *minAppversion="3">
              <form nz-form nzLayout="vertical" [formGroup]="personalForm" (ngSubmit)="updateUser()">
                <div class="m-b-20">
                  <h3 class="m-0">
                    Personalakte
                  </h3>
                  <small>Die digitale Personalakte kann nur von Administratoren eingesehen und bearbeitet
                    werden.</small>
                </div>
                <nz-row nzGutter="16">
                  <div nz-col nzSpan="6">
                    <nz-form-item>
                      <nz-form-label>Geburtsdatum</nz-form-label>
                      <input nz-input mbsc-datepicker formControlName="birthday" theme="ios" placeholder="01.01.2000"/>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="6">
                    <nz-form-item>
                      <nz-form-label nzFor="nationality">Nationalität</nz-form-label>
                      <nz-form-control>
                        <nz-select formControlName="nationality" [nzShowSearch]="true" nzPlaceHolder="Nationalität">
                          <nz-option *ngFor="let nationality of countryService.getAllNationalities()"
                                     [nzValue]="nationality.code"
                                     [nzLabel]="nationality.nationality"></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="6">
                    <nz-form-item>
                      <nz-form-label>Eintrittsdatum</nz-form-label>
                      <input nz-input mbsc-datepicker formControlName="startDate" theme="ios" placeholder="01.01.2000"/>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="6">
                    <nz-form-item>
                      <nz-form-label>Austrittsdatum</nz-form-label>
                      <input nz-input mbsc-datepicker formControlName="endDate" theme="ios" placeholder="01.01.2000"/>
                    </nz-form-item>
                  </div>
                </nz-row>

                <nz-row nzGutter="16">
                  <div nz-col nzSpan="12">
                    <nz-form-item>
                      <nz-form-label nzFor="address">Straße und Hausnummer</nz-form-label>
                      <nz-form-control>
                        <input formControlName="address" nz-input placeholder="Straße und Hausnummer">
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="4">
                    <nz-form-item>
                      <nz-form-label nzFor="zip">Postleitzahl</nz-form-label>
                      <nz-form-control>
                        <input formControlName="zip" nz-input placeholder="Postleitzahl">
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="8">
                    <nz-form-item>
                      <nz-form-label nzFor="city">Ort</nz-form-label>
                      <nz-form-control>
                        <input formControlName="city" nz-input placeholder="Ort">
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </nz-row>

                <nz-row nzGutter="16">
                  <div nz-col nzSpan="12">
                    <nz-form-item>
                      <nz-form-label nzFor="country">Land</nz-form-label>
                      <nz-form-control>
                        <nz-select formControlName="country" [nzShowSearch]="true" nzPlaceHolder="Land">
                          <nz-option *ngFor="let country of countryService.getAllCountries()" [nzValue]="country.code"
                                     [nzLabel]="country.name"></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </nz-row>

                <nz-form-item>
                  <nz-form-label>Zusätzliche Informationen</nz-form-label>
                  <nz-form-control>
                    <textarea formControlName="note" nz-input rows="3"
                              placeholder="Zusätzliche Informationen können hier eingetragen werden"></textarea>
                  </nz-form-control>
                </nz-form-item>

                <div class="text-right m-b-20">
                  <button nz-button nzType="primary" type="submit"
                          [disabled]="personalForm.invalid">
                    {{ user.personalFile ? 'Speichern' : 'Personalakte anlegen' }}
                  </button>
                </div>
              </form>

              <div class="m-b-20">
                <h3 class="m-0">
                  Datenablage
                </h3>
                <small>Hier können Sie alle personalbezogenen Dateien, wie z.B. das Personalstammblatt, Arbeitsverträge,
                  etc. hinterlegen.</small>
              </div>

              <app-file-list *ngIf="user.personalFile; else noPersonalFile" [hideManagerOnlySelect]="true"
                             [fileUploadManagerOnly]="true" [personalFileUserId]="user.id"></app-file-list>
              <ng-template #noPersonalFile><p>Bevor Datein hochgeladen werden können, muss die Personalakte angelegt
                werden.</p></ng-template>
            </div>
          </nz-tab>

          <nz-tab [nzDisabled]="!user.id">
            <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tab: 'timetracking' }"
               queryParamsHandling="merge">
              <i nz-icon nzType="clock-circle" nzTheme="outline"></i> Zeiterfassung <i *ngIf="timeTrackingForm?.invalid"
                                                                                       class="color-red m-l-5" nz-icon
                                                                                       nzType="exclamation-circle"
                                                                                       nzTheme="fill"></i>
            </a>
            <form nz-form nzLayout="vertical" [formGroup]="timeTrackingForm" (ngSubmit)="updateUser()">
              <div *ngIf="user && user.renewinterval?.module_timetracking">
                <h3>Arbeitszeiten in Stunden</h3>
                <div nz-row>

                  <div nz-col nzSpan="24">

                    <app-time-tracking-work-week
                      [(timeTrackingWorkWeek)]="this.user.timeTrackingWorkWeek"></app-time-tracking-work-week>

                    <h3 class="m-t-20">Pausenzeitberechnung</h3>
                    <label class="m-t-10" nz-checkbox formControlName="prescribeBreakTime">Automatische Pausenberechnung
                      nach §4 ARBZG</label>
                  </div>
                </div>

                <div nz-row>
                  <div nz-col nzSpan="24">
                    <h3 class="m-t-20">Urlaubsanspruch</h3>

                    <nz-form-item>
                      <nz-form-control>
                        <nz-form-label nzFor="yearlyVacationClaim">Jährlicher Urlaubsanspruch in Tagen: </nz-form-label>

                        <input nz-input style="width: 70px" class="m-l-10" type="text" nzSize="small" (keyup)="commaToPoint($event)" placeholder="0" formControlName="yearlyVacationClaim"/>

                        <i nz-tooltip
                           nzTooltipTitle="Um den Urlaubsanspruch zu berechnen muss dieses Feld größer als 0 sein. Es sind nur ganze oder halbe ( .5) Zahlen erlaubt."
                           class="m-l-10"
                           style="color: #FF8F00;"
                           nz-icon
                           nzType="info-circle"
                           nzTheme="outline"></i>

                      </nz-form-control>
                     </nz-form-item>
                  </div>
                </div>
              </div>



              <div nz-row *ngIf="vacationClaims.controls?.length > 0">
                <div nz-col nzSpan="24">
                  <ng-container formArrayName="vacationClaims">
                    <nz-table #vacationClaimTable [nzPageSize]="5" nzTemplateMode>
                      <thead>
                      <tr>
                        <th>Jahr</th>
                        <th>Gesamtanspruch</th>
                        <th>Resturlaub</th>
                        <th>Zu nehmen bis</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngIf="!vacationClaimYearExists(years[2])">
                        <td class="ghost">{{ years[2] }}</td>
                        <td colspan="4">
                          <button [disabled]="!this.user.yearlyVacationClaim"
                                  (click)="calcVacationClaim(years[2])" nz-button nzType="dashed" ><i nz-icon nzType="plus-circle" nzTheme="outline"></i> Urlaubsanspruch für {{ years[2] }} anlegen</button>
                        </td>
                      </tr>
                      <tr [formGroup]="vacationClaim" [ngClass]="{ 'color-red' : vacationClaim.get('expired')?.value }"
                          *ngFor="let vacationClaim of vacationClaims.controls">
                        <td>{{ vacationClaim.get('year')?.value }}</td>
                        <td>
                          <nz-input-number style="width: 70px;" nzSize="small" formControlName="total" [nzMin]="0"
                                           [nzMax]="366"
                                           [nzStep]="1"></nz-input-number>
                          Tage
                        </td>
                        <td>{{ vacationClaim.get('remaining')?.value }} Tage</td>
                        <td style="width: 170px">
                          <input nz-input mbsc-datepicker
                                 formControlName="expiry"
                                 theme="ios"/>


                        </td>
                        <td>
                          <nz-tag *ngIf="vacationClaim.get('expired')?.value" [nzColor]="'red'">Abgelaufen</nz-tag>
                        </td>
                      </tr>
                      <tr *ngIf="!vacationClaimYearExists(years[0])">
                        <td class="ghost">{{ years[0] }}</td>
                        <td colspan="4">
                          <button [disabled]="!this.user.yearlyVacationClaim" nz-popconfirm
                                  nzPopconfirmTitle="Sind Sie sicher, dass Sie für das vergangene Jahr einen Urlaubsanspruch erzeugen möchten?"
                                  [nzCondition]="years[0] >= currentYear"
                                  (nzOnConfirm)="calcVacationClaim(years[0])" nz-button nzType="dashed" ><i nz-icon nzType="plus-circle" nzTheme="outline"></i> Urlaubsanspruch für {{ years[0] }} anlegen</button>
                        </td>
                      </tr>
                      </tbody>
                    </nz-table>
                  </ng-container>
                </div>
              </div>
              <div class="text-right m-t-20 m-b-20">
                <button nz-button nzType="primary" type="submit"
                        [disabled]="userForm.invalid || timeTrackingForm.invalid || !user.renew_interval_id">
                  Speichern
                </button>
              </div>
            </form>
          </nz-tab>
          <nz-tab [nzDisabled]="!user.id">

            <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tab: 'absence' }"
               queryParamsHandling="merge">
              <nz-badge [nzCount]="absentComponent?.waitingForApprovalCount"><i nz-icon nzType="user-delete"
                                                                               nzTheme="outline"></i> Abwesenheiten
              </nz-badge>
            </a>

            <app-absent *ngIf="user.id" #absentComponent [userId]="user?.id"></app-absent>
          </nz-tab>
          <nz-tab [nzDisabled]="!user.id">
            <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tab: 'devices' }"
               queryParamsHandling="merge">
              <i nz-icon nzType="desktop" nzTheme="outline"></i> Geräte
            </a>
            <app-session-list *appIsManager [userId]="userId"></app-session-list>
          </nz-tab>
        </nz-tabset>


      </nz-spin>
    </nz-card>


  </div>
  <div nz-col nzSpan="8">
    <ng-container *ngIf="userId === 'new' && !user.id">
      <app-select-user-billing-interval [(renewIntervalId)]="user.renew_interval_id"></app-select-user-billing-interval>
    </ng-container>

    <nz-card *ngIf="user && user.id && !user.isActive">


      <div>
        <h3 class="title color-red">
          <i nz-icon nzType="warning" nzTheme="outline"></i> Benutzer ist deaktiviert
        </h3>
      </div>

      <p>Wenn ein Benutzer deaktiviert ist, wird der Zugang für den gewählten Benutzer zur Administration und zur App
        gesperrt. Ein deaktivierter Benutzer wird in zukünftigen Abrechnungen <strong>nicht</strong> berücksichtigt.</p>

      <p *ngIf="user.nextRenew">
        Bezahlt bis:
        <strong> {{ user.nextRenew | date: 'dd.MM.yyyy' }}</strong>
      </p>
      <div class="text-right">
        <button [disabled]="authService.currentUserValue?.company?.billing?.terminated" nz-button nzType="primary"
                (click)="enableUser()">Benutzer reaktivieren
        </button>
      </div>

    </nz-card>

    <nz-card *ngIf="user && user.id">


      <h3 class="title">
        Informationen
      </h3>

      <nz-descriptions nzSize="small" nzBordered>
        <nz-descriptions-item nzTitle="Erstellt" [nzSpan]="3">
          {{ user.createdAt | date: 'dd.MM.yyyy' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Status" [nzSpan]="3">
          <nz-badge *ngIf="user.isActive" nzStatus="success" nzText="Aktiv"></nz-badge>
          <nz-badge *ngIf="!user.isActive" nzStatus="error" nzText="Inaktiv"></nz-badge>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Paket & Abrechnung" [nzSpan]="3">
          <a *ngIf="user.isActive" (click)="editRenewInterval()">{{ user.renewinterval?.name }} <i nz-icon nzType="edit"
                                                                                                   nzTheme="outline"></i></a>
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="user.isActive" nzTitle="Nächste Abrechnung" [nzSpan]="3">
          {{ user.nextRenew | date: 'dd.MM.yyyy' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Zeiterfassung" [nzSpan]="3">
          <nz-badge *ngIf="user.renewinterval?.module_timetracking" nzStatus="success" nzText="Lizenziert"></nz-badge>
          <nz-badge *ngIf="!user.renewinterval?.module_timetracking" nzStatus="error"
                    nzText="Nicht Lizenziert"></nz-badge>
        </nz-descriptions-item>
      </nz-descriptions>

      <ng-container *ngIf="user.isActive && (authService.currentUserValue.id !== user.id)">
        <button nz-button nzBlock nzType="link" class="m-t-10" nzDanger (click)="disableUser()">Benutzer deaktivieren
        </button>
      </ng-container>
    </nz-card>

    <ng-container *ngIf="userId !== 'new'">
      <nz-card *ngIf="user">
        <div nz-row>
          <div nz-col nzSpan="24">
            <img class="rounded-profile-img m-h-30"
                 [src]="user.avatarPath ? backendURL + '/' + user?.avatarPath : '../../../../assets/user.png'">

            <nz-upload
              [nzName]="'file'"
              [nzAction]="backendURL + '/admin/avatar'"
              [nzData]="{user_id: user.id}"
              [nzFileType]="'image/png,image/jpeg,image/gif'"
              [nzAccept]="'.png, .jpeg, .jpg, .gif'"
              [nzHeaders]="{ apiKey: authService.session | async }"
              (nzChange)="onChange($event)"
              [nzShowUploadList]="false"
            >
              <button *ngIf="!user.avatarPath" nzSize="small" nzType="primary" nzGhost class="m-l-15" nz-button>
                Profilbild hochladen
              </button>
              <button *ngIf="user.avatarPath" (click)="deleteAvatar(); $event.stopPropagation()" nzSize="small"
                      nzType="default" nzGhost nzDanger class="m-l-15" nz-button>
                Profilbild löschen
              </button>
            </nz-upload>
            <div style="opacity: .69" class="m-t-30 m-l-15">
              <p class="m-0">Erlaubte Dateiformate '*.jpg, *.jpeg, *.png, *.gif'</p>
            </div>
          </div>
        </div>
      </nz-card>

    </ng-container>


    <nz-card *ngIf="user && user.id && user.isActive">
      <div>

        <div>
          <h3 class="title">
            Passwort
          </h3>
        </div>

        <form nz-form nzLayout="vertical" [formGroup]="passwordForm">

          <nz-form-item>
            <nz-form-label nzFor="password">Neues Passwort</nz-form-label>
            <nz-form-control>
              <input (ngModelChange)="isTouched = true" formControlName="password" nz-input
                     placeholder="Neues Passwort">
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <label nz-checkbox formControlName="passwordChangeMessage">Benutzer benachrichtigen?</label>
            </nz-form-control>
          </nz-form-item>

        </form>
        <div class="text-right">
          <button nz-button nzType="primary" type="submit" [disabled]="!isTouched" (click)="passwordCancel()">Passwort
            ändern
          </button>
        </div>
      </div>
    </nz-card>

  </div>
</div>


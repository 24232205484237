<h2>Lexware Office Rechnung erzeugen</h2>

<nz-spin [nzSpinning]="loading">

  <div nz-row [nzGutter]="[8, 8]">
    <div nz-col nzSpan="8">
      <nz-divider nzText="Adresse wählen"></nz-divider>

      <nz-select class="w-100" nzOptionHeightPx="66" nzShowSearch nzAllowClear nzPlaceHolder="Adresse wählen"
                 [(ngModel)]="lxInvoice.address">
        <nz-option-group nzLabel="Kontakte">
          <nz-option nzCustomContent *ngFor="let address of lxadresses.contacts" [nzLabel]="address.name" [nzValue]="address">
            <strong>{{ address.name }}</strong>
            <lexoffice-icon class="m-l-10" *ngIf="address.contactId"></lexoffice-icon>
            <br>
            <i *ngIf="address._noaddress">Für diesen Kontakt wurden keine Adressen
              gefunden.</i>
            {{ address.street }}<br>
            {{ address.zip }} {{ address.city }}

          </nz-option>
        </nz-option-group>
        <nz-option-group nzLabel="Objekte" *ngIf="lxadresses.objects.length > 0">
          <nz-option nzCustomContent *ngFor="let address of lxadresses.objects" [nzLabel]="address.name" [nzValue]="address">
            <strong>{{ address.name }}</strong>
            <br>
            {{ address.street }}<br>
            {{ address.zip }} {{ address.city }}
          </nz-option>
        </nz-option-group>



      </nz-select>
      <small>Adressen werden automatisch anhand verknüpfter Objekte und Kontakte ermittelt</small>

      <nz-divider nzText="Einstellungen"></nz-divider>

      <nz-collapse>
        <nz-collapse-panel
          nzHeader="Überschriften"
          [nzActive]="true"
        >
          <label nz-checkbox [(ngModel)]="config.headlines">Überschriften für Protokolle als Freitext</label>
          <div class="sub m-b-10">
            <label>Prefix für Überschrift:</label>
            <input [(ngModel)]="config.headlines_prefix" [disabled]="!config.headlines" nz-input
                   placeholder="Prefix steht vor dem Datum" nzSize="small"/>
          </div>
          <label class="sub" nz-checkbox [disabled]="!config.headlines" [(ngModel)]="config.headlines_description">Tätigkeit
            übernehmen</label><br>
          <label class="sub" nz-checkbox [disabled]="!config.headlines" [(ngModel)]="config.headlines_services">Leistungen
            auflisten</label><br>
          <label class="sub" nz-checkbox [disabled]="!config.headlines" [(ngModel)]="config.headlines_gps">GPS-Stempel
            auflisten</label>
        </nz-collapse-panel>
        <nz-collapse-panel
          nzHeader="Zeiten"
          [nzActive]="true"
        >
          <label nz-checkbox [(ngModel)]="config.times">Arbeitszeiten Start / Ende übernehmen</label><br>

          <div class="sub m-b-10">
            <label>Arbeitszeit mit folgendem Lexware Office Service buchen:</label>
            <nz-select [disabled]="!config.times" class="w-100 m-t-5" nzShowSearch nzAllowClear
                       nzPlaceHolder="Lexware Office Service für Arbeitszeit wählen"
                       [(ngModel)]="config.worktimeArticle">
              <nz-option *ngFor="let consumable of allConsumablesLexoffice" [nzLabel]="consumable.name"
                         [nzValue]="consumable.id"></nz-option>
            </nz-select>
            <small>Der Artikel / Service sollte die Einheit Stunden und einen Preis hinterlegt haben. Wenn nichts
              gewählt wurde, wird die Arbeitszeit zu einer manuellen Position.</small>
          </div>
          <label class="sub" nz-checkbox [disabled]="!config.times" [(ngModel)]="config.times_calc">Arbeitszeiten aus
            Start & Ende berechnen</label><br>
        </nz-collapse-panel>
        <nz-collapse-panel
          nzHeader="Verbrauchsmaterial"
          [nzActive]="true"
        >
          <label nz-checkbox [(ngModel)]="config.consumables">Verbrauchsmaterial übernehmen</label><br>
          <label class="sub" nz-checkbox [disabled]="!config.consumables" [(ngModel)]="config.consumables_lexwareOnly">Nur
            mit Lexware Office verknüpfte Artikel / Services</label>
        </nz-collapse-panel>
      </nz-collapse>


    </div>
    <div nz-col nzSpan="16">


      <nz-divider nzText="Vorschau"></nz-divider>

      <div *ngIf="!lxInvoice.address" class="color-red text-center">Wählen Sie zuerst eine Adresse um fortfahren zu können</div>

      <ng-container *ngIf="lxInvoice.address">
        <button nz-button nzType="primary" (click)=" this.generatePreview()" nzBlock>
          Vorschau aus Einstellungen
          erzeugen {{ lxInvoice.lineItems?.length > 0 ? 'und aktuelle Werte überschreiben' : '' }}
        </button>

        <div class="invoice-preview m-t-20">

          <nz-table nzSize="small" nzTemplateMode>
            <thead>
            <tr>
              <th></th>
              <th>Produkt / Service</th>
              <th style="width: 100px">Menge</th>
              <th style="width: 160px">Einheit</th>
              <th style="width: 130px">Einzelpreis (€)</th>

            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let lineItem of lxInvoice.lineItems; let i = index">
              <tr class="first-row" [ngClass]="{ 'disabled' : !lineItem._selected }">
                <td>
                  <label nz-checkbox [(ngModel)]="lineItem._selected"></label>
                </td>
                <td [colSpan]="lineItem.type === 'text' ? 4 : 1">
                  <input nz-input [(ngModel)]="lineItem.name">
                </td>
                <ng-container *ngIf="lineItem.type !== 'text'">
                  <td><input nz-input type="number" [(ngModel)]="lineItem.quantity"></td>
                  <td><input nz-input [(ngModel)]="lineItem.unitName"></td>

                  <td><input #amountMask nz-input [(ngModel)]="lineItem.unitPrice.netAmount"></td>
                </ng-container>

                <!--  <td>
                    <button (click)="cloneLineItem(i)" nz-button nzType="default" class="m-r-5" nzShape="circle"><span nz-icon
                                                                                                                       nzType="copy"></span>
                    </button>
                    <button (click)="deleteLineItem(i)" nz-button nzType="link" nzDanger nzShape="circle"><span nz-icon
                                                                                                                nzType="delete"></span>
                    </button>
                  </td>-->
              </tr>
              <tr [ngClass]="{ 'disabled' : !lineItem._selected }">
                <td>
                  <lexoffice-icon *ngIf="lineItem.id; else manualItem" nz-tooltip
                                  nzTooltipTitle="Lexware Office Artikel"></lexoffice-icon>
                  <ng-template #manualItem>
                  <span class="text-bold" nz-tooltip
                        nzTooltipTitle="{{ lineItem.type === 'text' ? 'Text' : 'Manuelle'}} Position">{{ lineItem.type === 'text' ? 'T' : 'M' }}</span>
                  </ng-template>

                </td>
                <td colspan="4">
                <textarea class="m-t-5" nz-input [(ngModel)]="lineItem.description"
                          placeholder="Beschreibung"></textarea>
                </td>

              </tr>

            </ng-container>

            </tbody>
          </nz-table>

        </div>

        <p class="m-v-10">Die Rechnung wird als Entwurf in Lexware Office erzeugt und kann anschließend direkt in Lexware
          Office bearbeitet
          werden.</p>
      </ng-container>

    </div>
  </div>


  <div style="min-height: 35px" class="m-t-10">
    <nz-button-group class="pull-right">
      <button nz-button [disabled]="!this.lxInvoice.address || this.lxInvoice.lineItems.length <= 0"
              (click)="sendToLexoffice()">
        <lexoffice-icon class="m-r-5"></lexoffice-icon>
        Rechnung als Entwurf erzeugen
      </button>
      <button nz-button nz-dropdown [nzDropdownMenu]="sendMenu" nzPlacement="bottomRight">
        <span nz-icon nzType="down"></span>
      </button>
    </nz-button-group>
    <nz-dropdown-menu #sendMenu="nzDropdownMenu">
      <ul nz-menu>
        <li [nzDisabled]="!this.lxInvoice.address || this.lxInvoice.lineItems.length <= 0"
            (click)="sendToLexoffice(true)" nz-menu-item>
          <lexoffice-icon class="m-r-5"></lexoffice-icon>
          Rechnung erzeugen und&nbsp;<strong>abschließen</strong></li>
      </ul>
    </nz-dropdown-menu>

  </div>

</nz-spin>

<nz-spin [nzSpinning]="loading">
  <h2>{{ title }}</h2>
  <nz-divider></nz-divider>
  <form nz-form [formGroup]="taskForm">
    <div nz-row>
      <div nz-col nzSpan="12">

        <div nz-row>
          <nz-form-item nz-col nzSpan="18" class="p-r-5">
            <nz-form-label nzRequired nzFor="title">Titel</nz-form-label>
            <nz-form-control>
              <input tabindex="10" formControlName="title" nz-input placeholder="Tätigkeit">
              <nz-autocomplete [nzDataSource]="serviceTemplates" nzBackfill #descauto></nz-autocomplete>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col nzSpan="6">
            <nz-form-label nzFor="color">Sonderfarbe</nz-form-label>
            <nz-form-control>
              <nz-select [nzDisabled]="task.wasteplan?.id" tabindex="20"
                         [style]="'color: ' + taskForm.get('color').value"
                         formControlName="color" nzAllowClear="true"
                         nzPlaceHolder="Farbe">
                <nz-option *ngFor="let col of availableColors" nzCustomContent [nzLabel]="col.name"
                           [nzValue]="col.color">
                  <span [style]="'color: ' + col.color">{{ col.name }}</span>
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item nz-col nzSpan="24" class="p-r-5">
            <nz-form-label nzRequired nzFor="resource">
              <nz-radio-group [(ngModel)]="resourceType" [ngModelOptions]="{standalone: true}" (ngModelChange)="taskForm.get('resource').setValue(null)">
                <label nz-radio nzValue="users">Mitarbeiter</label>
                <label nz-radio nzValue="team">Team</label>
              </nz-radio-group>
            </nz-form-label>
            <nz-form-control>
              <nz-select tabindex="40" formControlName="resource"
                         nzShowSearch [nzMode]="resourceType === 'users' ? 'multiple' : 'default'"
                         nzPlaceHolder="bitte wählen" nzSize="small"
              >
                <ng-container *ngIf="resourceType === 'users'">
                  <nz-option nzCustomContent *ngFor="let employee of users" [nzLabel]="employee.name"
                             [nzValue]="employee.id">
                    {{ employee.name }}
                  </nz-option>
                </ng-container>
                <ng-container *ngIf="resourceType === 'team'">
                  <nz-option nzCustomContent *ngFor="let team of teams"
                             [nzLabel]="team.name + ' (Team)'"
                             [nzValue]="team.id">
                    {{ team.name }}
                  </nz-option>
                </ng-container>

              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col nzSpan="24">
            <nz-form-label nzFor="description">Beschreibung</nz-form-label>
            <nz-form-control>
                        <textarea tabindex="30" [nzAutosize]="{ minRows: 2, maxRows: 6 }" formControlName="description"
                                  nzAutosize
                                  nz-input placeholder="Beschreibung"></textarea>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col nzSpan="24" *ngIf="task.id">
            <nz-form-label nzFor="task tags">Zugewiesene Tags</nz-form-label>
            <nz-form-control>
              <app-entity-tag-selector width="100%" [entity]="task" [entityType]="'task'"></app-entity-tag-selector>
            </nz-form-control>
          </nz-form-item>
        </div>

        <nz-radio-group tabindex="60" [(ngModel)]="timeType" (ngModelChange)="this.setMasks()"
                        [ngModelOptions]="{standalone: true}">
          <label tabindex="61" nz-radio [nzValue]="'timeRange'">Zeit festlegen</label>
          <label tabindex="62" nz-radio [nzValue]="'allDay'">Ganztägig</label>
          <label *ngIf="!task.wasteplan?.id" tabindex="63" nz-radio [nzValue]="'backlog'">Zeit noch nicht
            festlegen</label>

        </nz-radio-group>
        <div class="m-v-20" *ngIf="timeType === 'backlog'">
          <nz-alert nzType="info"
                    nzMessage="Die Aufgabe wird ins Backlog gelegt und kann später eingeplant werden."></nz-alert>
        </div>
        <div class="m-t-10">
          <div nz-row *ngIf="timeType !== 'backlog'">
            <nz-form-item nz-col nzSpan="12" class="p-r-5">

              <nz-form-control>
                <mbsc-datepicker
                  #dateTimeMask
                  tabindex="70"
                  inputStyle="outline"
                  formControlName="start"
                  [controls]="[(timeType === 'timeRange' ? 'datetime' : 'date')]"
                  [label]="'Start'"
                  labelStyle="stacked"
                  dataTimezone="utc"
                  displayTimezone="local"
                  [timezonePlugin]="momentPlugin"
                  theme="ios"
                  (onTempChange)="autoSetEndDate($event)"
                  [stepMinute]="1">
                </mbsc-datepicker>

              </nz-form-control>
            </nz-form-item>
            <nz-form-item nz-col nzSpan="12">

              <nz-form-control>
                <mbsc-datepicker
                  #dateTimeMask
                  tabindex="75"
                  inputStyle="outline"
                  formControlName="end"
                  [controls]="[(timeType === 'timeRange' ? 'datetime' : 'date')]"
                  [label]="'Endzeit'"
                  labelStyle="stacked"
                  dataTimezone="utc"
                  displayTimezone="local"
                  [timezonePlugin]="momentPlugin"
                  theme="ios"
                  [stepMinute]="1">
                </mbsc-datepicker>
              </nz-form-control>
            </nz-form-item>

            <nz-alert nz-col nzSpan="24" class="m-b-10" *ngIf="taskForm?.errors" nzType="error"
                      [nzMessage]="taskForm?.errors?.dates"
                      nzShowIcon></nz-alert>
          </div>


        </div>

      </div>
      <div class="p-l-20" nz-col nzSpan="12">

        <nz-form-item nz-col nzSpan="24">
          <nz-form-control>
            <app-object-customer-select [disabled]="task.wasteplan?.id" [(room)]="task.room" [roomSelect]="true"
                                        [multiple]="false"
                                        [(reference)]="reference"
                                        (referenceChange)="referenceSelected($event)"></app-object-customer-select>
          </nz-form-control>
        </nz-form-item>

        <!--            SERIVCES-->
        <div>
          <h6>Leistungen</h6>
          <ng-container *ngIf="task.selectedServices?.length > 0">
            <div class="editable-row" *ngFor="let data of task.selectedServices; let i = index">
              <div class="p-v-5">
                <nz-input-group [nzSuffix]="suffixIcon">
                  <input (ngModelChange)="isTouched = true" type="text" nz-input
                         [(ngModel)]="data.name"
                         [ngModelOptions]="{standalone: true}"/>
                </nz-input-group>
                <ng-template #suffixIcon>
                  <i class="p-l-5 pointer" (click)="deleteCompletedService(i)" nz-icon nzType="delete"
                     nzTheme="outline"></i>
                </ng-template>
              </div>
            </div>
          </ng-container>

          <div class="m-t-10">
            <button nz-button nzType="link" nzSize="small" nz-dropdown [nzDropdownMenu]="menu4">
              <i nz-icon nzType="plus-circle" nzTheme="outline"></i> Hinzufügen
              <span nz-icon nzType="down"></span>
            </button>

            <nz-dropdown-menu #menu4="nzDropdownMenu">
              <ul nz-menu>
                <li (click)="openCatalog()" nz-menu-item>aus Leistungskatalog <i class="p-l-5" nz-icon
                                                                                 nzType="book"
                                                                                 nzTheme="outline"></i></li>
                <li (click)="addOwnService()" nz-menu-item>Position erstellen <i class="p-l-5" nz-icon
                                                                                 nzType="edit"
                                                                                 nzTheme="outline"></i></li>
              </ul>
            </nz-dropdown-menu>
          </div>
        </div>



        <div *ngIf="task.id" class="m-t-10" style="max-height: 450px;">
          <h6><i nz-icon nzType="inbox" nzTheme="outline"></i> Ticket Verknüpfung</h6>
          <ng-container *ngIf="!ticket">
            <div style="display: inline-flex">
              <div class="ticket-container">
                <a
                  nz-button
                  nzSize="small"
                  nzType="link"
                  (click)="toggleTicketSearch(); $event.stopPropagation();"
                >
                  <i nz-icon nzType="inbox" nzTheme="outline"></i> Ticket verknüpfen
                </a>

                <div class="ticketSearch" [ngClass]="{'showTicket': showTicketSearch}">
                  <app-global-search #globalSearchTICKETComponent [hideLinkTicketBtn]="true"
                                     [hideClose]="false"
                                     [searchCategories]="'TICKETS'" (showsearchChange)="handleShowSearchChange($event)"
                                     (linkTicketToWorklog)="linkTicket($event)"
                                     [infoTooltip]="'Hier können Sie nach Ticketnamen und den zugehörigen Objektnamen suchen.'"
                                     [searchPlaceholder]="'Ticket suchen'"></app-global-search>
                </div>
              </div>

              <a (click)="createTicket()" [disabled]="showTicketSearch" class="m-l-10" nz-button nzSize="small"
                 nzType="link"><i nz-icon nzType="plus-circle"
                                  nzTheme="outline"></i> Ticket
                anlegen</a>
            </div>
          </ng-container>


          <div *ngIf="ticket">
            <div nz-row class="m-b-5" nzJustify="space-around" nzAlign="middle">
              <ticket-tag nz-col nzSpan="21" [openNewTab]="true" [ticketId]="ticket?.id"></ticket-tag>
              <div nz-col nzSpan="3">
                <button [disabled]="!task.id" (click)="deleteTaskTicketRelation(task)" nzShape="circle" class="m-l-10"
                        nz-tooltip="Verlinkung aufheben"
                        nzSize="small" nzDanger nz-button nzType="link"><i nz-icon
                                                                           nzType="link"
                                                                           nzTheme="outline"></i>
                </button>
              </div>
            </div>
          </div>
        </div>


        <!--WORKLOG LINK-->
        <ng-container>
          <div style="max-height: 450px; overflow: auto; margin-top: 15px;">
            <h6><i nz-icon nzType="file-done"></i> Arbeitsprotokolle</h6>
            <label *appIsManager nz-checkbox nz-tooltip class="m-t-5"
                   nzTooltipTitle="Hier können Sie festlegen ob bei der Erledigung einer Aufgabe ein Arbeitprotokoll erstellt werden muss"
                   formControlName="worklogneeded">Arbeitsprotokoll Pflicht</label>
            <a style="vertical-align: top" *ngIf="task.id" (click)="newWorklog()" nz-button nzSize="small" nzType="link">
              <i nz-icon nzType="plus-circle" nzTheme="outline"></i> Arbeitsprotokoll anlegen</a>
            </div>

          <div>
              <nz-table nzTemplateMode>
                <tr *ngFor="let worklog of worklogs">
                  <td> {{ worklog.createdAt | date:'dd.MM.y' }}</td>
                  <td>
                    <i nz-icon nzType="file-done"
                       nzTheme="outline"></i> {{ worklog.creator?.name }}<br>

                  </td>
                  <td><a (click)="editWorklog(worklog)"><i nz-icon nzType="select"
                                                           nzTheme="outline"></i> öffnen</a></td>
                </tr>
              </nz-table>



          </div>
        </ng-container>


        <nz-alert *ngIf="task.counters?.length > 0" class="m-t-10" nzType="info" nzMessage="Diese Aufgabe ist mit {{ task.counters?.length }} Zählern verknüpft"></nz-alert>

      </div>


      <div nzSpan="24" class="m-t-10" *ngIf="timeType !== 'backlog' && !task.wasteplan?.id">
        <label class="m-b-20" tabindex="80" *ngIf="!ticket" [disabled]="task?.counters?.length > 0" nz-checkbox [(ngModel)]="isRecurring"
               [ngModelOptions]="{standalone: true}">Aufgabe
          automatisch wiederholen</label>
        <small class="m-b-20" nz-col nzSpan="24" *ngIf="ticket"><i nz-icon nzType="info-circle"
                                                                   nzTheme="outline"></i>
          Aufgabe automatisch
          wiederholen ist in Verbindung mit Tickets nicht möglich.</small>


        <app-recurring-editor *ngIf="isRecurring" [(recurringRule)]="task.recurring" nz-col
                              nzSpan="24"></app-recurring-editor>


      </div>

      <nz-divider></nz-divider>

      <div nz-col nzSpan="24">
        <ng-container *ngIf="task.id">
          <button *appIsManager [disabled]="isTempId" nz-button nzType="link" type="button" nzDanger
                  (click)="deleteTask()">Löschen
          </button>
          <button *appIsManager [hidden]="task.wasteplan?.id" nz-button nzType="link" type="button"
                  (click)="duplicate()">Duplizieren
          </button>
        </ng-container>


        <div class="pull-right">
          <button tabindex="120" class="m-h-10" nz-button nzType="default" (click)="cancel()" type="button">
            Abbrechen
          </button>
          <button tabindex="115" nz-button nzType="primary" type="submit" (click)="save()"
                  [disabled]="taskForm.invalid">
            Speichern
          </button>
        </div>
      </div>
    </div>
  </form>
</nz-spin>

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {Router} from '@angular/router';
import {LexofficeService} from '../services/lexoffice.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthenticationService,
    private notification: NzNotificationService,
    private router: Router,
    public lexofficeService: LexofficeService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {



        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.log('client side error');
        } else {
          console.log('server side error');
        }

        if (!request.url.includes('lexoffice')) {

          if (error.status === 401 && error.error.logout) {
            console.error(error.status, 'LOGOUT');
            this.authService.logout(true);
          }
          if (error.error?.error === 'api key missing' && error.status === 403) {
            console.error(error.status, 'LOGOUT api key missing');
            this.authService.logout(true);
          }

          if (error.status === 429) {
            this.notification.create(
              'error',
              'Zu viele Anfragen',
              'Sie haben in kürzester Zeit zu viele Anfragen an den Server gesendet. Bitte versuchen Sie es in ein paar Sekunden erneut.',
              {nzDuration: 6000}
            );
          }
        } else {
          if (error.status === 401) {
            this.notification.create(
              'error',
              'Lexware Office',
              'Die Verbindung zu Lexware Office ist abgelaufen und muss neu eingerichtet werden.'
            );
            if (!request.url.includes('super-admin')) {
              this.lexofficeService.startOauth()
            }

          }
          if (error.status === 429) {
            this.notification.create(
              'error',
              'Schnittstelle überlastet',
              'Die Schnittstelle zu Lexware Office ist überlastet. Bitte versuchen Sie es in ein paar Sekunden erneut.',
              {nzDuration: 6000}
            );
          }
        }

        throw error;
      })
    )
  }
}

import {Component, inject, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Contact, Object, Task, Ticket, User, WorkLog} from '../../../../../../database-models';
import moment from "moment";
import {ApiService} from "../../../services/api.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {ActivatedRoute, Router} from "@angular/router";
import {PrintService} from "../../services/print.service";
import {AuthenticationService} from "../../services/authentication.service";
import {UserSelectComponent} from "../user-select/user-select.component";
import {EditWorklogComponent, ModalData as EditWorklogModalData} from "../edit-worklog/edit-worklog.component";

export interface ModalData {
  worklog?: WorkLog;
}

@Component({
  selector: 'app-new-worklog',
  templateUrl: './new-worklog.component.html',
  styleUrls: ['./new-worklog.component.css']
})
export class NewWorklogComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @ViewChild('userSelectComponent')
  private userSelectComponent: UserSelectComponent;

  @Input() public worklog: WorkLog;

  public users: User[];
  public reference: Object | Contact;


  public worklogForm: FormGroup;

  constructor(
      public formBuilder: FormBuilder,
      public api: ApiService,
      private message: NzMessageService,
      private modalService: NzModalService,
      public route: ActivatedRoute,
      public router: Router,
      private printService: PrintService,
      private authenticationService: AuthenticationService,
      @Optional() private modal: NzModalRef
  ) {
  }

  ngOnInit(): void {

    this.worklog ??= this.data?.worklog;
    this.worklog ??= {} as WorkLog;


    if (this.worklog.object) {
      this.referenceSelected(this.worklog.object);
    }
    if (this.worklog.contact) {
      this.referenceSelected(this.worklog.contact);
    }

    this.worklog.creator ??= this.authenticationService.currentUserValue;

    this.initForm();
    this.getUsers();
  }

  public initForm(): void {
    this.worklogForm = this.formBuilder.group(
        {
          description: [''],
          creator: [this.worklog.creator, [Validators.required]],
          object: [this.worklog.object],
          contact: [this.worklog.contact],
          start: [moment(this.worklog.start).format(), [Validators.required]],
          end: [this.worklog.end],
        },
        {
          validators: this.dateLessThan("start", "end"),
        }
    );
  }

  public checkUser(value: User): void {
    if (value) {
      this.worklogForm.get('creator').setValue(value);
    }
  }

  public save(): void {
    const worklogToSave: WorkLog = this.worklogForm.getRawValue();
    worklogToSave.object_id = this.worklog.object_id;
    worklogToSave.contact_id = this.worklog.contact_id;
    worklogToSave.employee_id = this.worklog.creator.id;
    worklogToSave.signatures = this.worklog.signatures;
    worklogToSave.room_id = this.worklog.room?.id;
    worklogToSave.task_id = this.worklog.task?.id;
    worklogToSave.ticket_id = this.worklog.ticket_id;

    this.api.setWorklog(worklogToSave).subscribe((value: WorkLog) => {
      this.message.success("Erfolgreich gespeichert");
      this.modal.close({reason: 'success', data:value});
      this.editWorklog(value);
    }, error => {
    });
  };

  public editWorklog(worklog: WorkLog): void {
    const newObjectModal = this.modalService.create<EditWorklogComponent, EditWorklogModalData>({
      nzContent: EditWorklogComponent,
      nzData: {
        worklogId: worklog.id
      },
      nzWidth: '1500px',
      nzFooter: null,
      nzMaskClosable: true,
      nzKeyboard: false
    });

    newObjectModal.afterClose.subscribe((data: any) => {
    });
  }

  public referenceSelected(value: Object | Contact): void {
    console.log(value);
    this.reference = value;
    switch (value.member) {
      case "Object":
        this.worklog.object = value;
        this.worklog.object_id = value.id;
        this.worklog.contact_id = null;
        this.worklog.contact = null;
        break;
      case "Contact":
        this.worklog.contact_id = value.id;
        this.worklog.contact = value;
        this.worklog.object_id = null;
        this.worklog.object = null;
        break;
    }
  };

  public cancel(): void {
    if (this.worklogForm.touched) {
      this.modalService.confirm({
        nzTitle: "<i>Bearbeiten abbrechen</i>",
        nzContent:
            "Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.",
        nzOnOk: () => this.modal.destroy({data: {reason: 'cancel'}}),
      });
    } else {
      this.modal.close();
    }
  }

  public getUsers(): void {
    this.api.getUsers().subscribe((employees: User[]) => {
          this.users = employees;
        },
        (onerror) => {
          console.log(onerror);
          this.message.error("Mitarbeiter konnten nicht geladen werden");
        }
    );
  }

  private dateLessThan(from: string, to: string): any {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];

      if (moment(f.value) > moment(t.value)) {
        return {
          dates: "Das Ende darf nicht vor dem Start liegen",
        };
      }
      return {};
    };
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }
}

import { Directive, EventEmitter, HostListener, Input, Output, Renderer2, SecurityContext } from '@angular/core';
import { environment } from 'src/environments/environment';
import { File } from '../../../../../database-models';
import { DomSanitizer } from '@angular/platform-browser';

const imageTypes = ['image/jpeg', 'image/png', 'image/gif'];

function fileIsImage(type: string): boolean {
  return imageTypes.includes(type);
}

function onlyImages(value: File[]): File[] {
  return value?.filter(i => fileIsImage(i.type)) || [];
}

@Directive({
  selector: '[lightbox]'
})
export class LightboxDirective {
  @Input() imageName!: string;
  @Input() imageDescription!: string;
  @Input() imageSrc!: string;
  @Input({transform: onlyImages}) thumbnails?: File[] = [];
  /**
   * if true, disables "edit", "delete", .. buttons
   */
  @Input() disableControls = false;

  @Output() delete = new EventEmitter<{file: File, index: number}>();
  @Output() edit = new EventEmitter<{file: File, index: number}>();

  private selectedImageIndex: number | null = null;
  private modalElement: any;

  constructor(private renderer: Renderer2, private sanitizer: DomSanitizer) {}

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    this.selectedImageIndex = this.thumbnails.findIndex(img => img.url === this.imageSrc);
    this.openModal();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.modalElement) {
      this.closeModal();
    }
  }

  openModal(): void {
    // Create the modal dynamically
    this.modalElement = this.renderer.createElement('div');
    this.renderer.addClass(this.modalElement, 'lightbox-modal');

    const controlsBar = this.renderer.createElement('div');
    this.renderer.addClass(controlsBar, 'controls-bar');

    if (!this.disableControls) {
      const editBtn = this.renderer.createElement('span');
      editBtn.innerHTML = 'Bearbeiten';
      this.renderer.addClass(editBtn, 'control-btn');
      this.renderer.listen(editBtn, 'click', () => this.editImage());
      this.renderer.appendChild(controlsBar, editBtn);

      const deleteBtn = this.renderer.createElement('span');
      deleteBtn.innerHTML = 'Löschen';
      this.renderer.addClass(deleteBtn, 'control-btn');
      this.renderer.listen(deleteBtn, 'click', () => this.deleteImage());
      this.renderer.appendChild(controlsBar, deleteBtn);
    }

    const closeBtn = this.renderer.createElement('span');
    closeBtn.innerHTML = '&times;';
    this.renderer.addClass(closeBtn, 'control-btn');
    this.renderer.listen(closeBtn, 'click', () => this.closeModal());
    this.renderer.appendChild(controlsBar, closeBtn);

    const modalContent = this.renderer.createElement('div');
    this.renderer.addClass(modalContent, 'modal-content');

    const fullImage = this.renderer.createElement('img');
    this.renderer.setAttribute(fullImage, 'src', `${environment.apiURL}/${this.imageSrc}`);
    this.renderer.setAttribute(fullImage, 'alt', this.imageName);
    this.renderer.addClass(fullImage, 'full-image');

    this.renderer.appendChild(modalContent, fullImage);
    this.renderer.appendChild(this.modalElement, controlsBar);
    this.renderer.appendChild(this.modalElement, modalContent);

    // Modal Thumbnails
    const thumbnailsDiv = this.renderer.createElement('div');
    this.renderer.addClass(thumbnailsDiv, 'thumbnails');

    const title = this.renderer.createElement('h4');
    const description = this.imageDescription ? `(${this.imageDescription})` : '';
    title.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, `${this.imageName} <small class="m-l-5">${description}</small>`);
    this.renderer.appendChild(thumbnailsDiv, title);

    this.thumbnails.forEach((img, index) => {
      const thumbnail = this.renderer.createElement('img');
      this.renderer.setAttribute(thumbnail, 'src', `${environment.apiURL}/${img.url}`);
      this.renderer.addClass(thumbnail, 'modal-thumbnail');

      if (this.selectedImageIndex === index) {
        this.renderer.addClass(thumbnail, 'selected');
      }

      this.renderer.listen(thumbnail, 'click', () => this.changeImage(index));
      this.renderer.appendChild(thumbnailsDiv, thumbnail);
    });

    this.renderer.appendChild(this.modalElement, thumbnailsDiv);

    // Add the modal to the body
    document.body.appendChild(this.modalElement);
    this.renderer.addClass(document.body, 'modal-open');
  }

  closeModal(): void {
    // Remove the modal from the DOM
    if (this.modalElement) {
      document.body.removeChild(this.modalElement);
      this.renderer.removeClass(document.body, 'modal-open');
      this.modalElement = null;
    }
  }

  changeImage(index: number): void {
    this.selectedImageIndex = index;

    // Update full-size image
    const fullImg = this.modalElement.querySelector('.full-image');
    this.renderer.setAttribute(fullImg, 'src', `${environment.apiURL}/${this.thumbnails[this.selectedImageIndex].url}`);

    // update title and description
    const title = this.modalElement.querySelector('h4');
    const description = this.thumbnails[this.selectedImageIndex].description ? `(${this.thumbnails[this.selectedImageIndex].description})` : '';
    title.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, `${this.thumbnails[this.selectedImageIndex].name} <small class="m-l-5">${description}</small>`);

    // Update modal thumbnails
    const thumbnails = this.modalElement.querySelectorAll('.modal-thumbnail');
    thumbnails.forEach((thumbnail: any, idx: number) => {
      if (idx === this.selectedImageIndex) {
        this.renderer.addClass(thumbnail, 'selected');
      } else {
        this.renderer.removeClass(thumbnail, 'selected');
      }
    });
  }

  editImage(): void {
    this.edit.emit({file: this.thumbnails[this.selectedImageIndex], index: this.selectedImageIndex});
    this.closeModal()
  }

  deleteImage(): void {
    this.delete.emit({file: this.thumbnails[this.selectedImageIndex], index: this.selectedImageIndex});
    this.closeModal()
  }
}

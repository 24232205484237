import {Component, ElementRef, inject, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivityLog, Appuser, Company, Roles, Team, Ticket, User, VacationClaim} from '../../../../../../database-models';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {AuthenticationService} from '../../services/authentication.service';
import {EditUserBillingIntervalComponent, ModalData as EditUserBillingIntervalModalData} from '../edit-user-billing-interval/edit-user-billing-interval.component';
import {environment} from "../../../../environments/environment";
import {AbsentComponent, ModalData as AbsentModalData} from '../absent/absent/absent.component';
import moment from 'moment';
import {momentTimezone} from '@mobiscroll/angular';
import {CountryService} from "../../services/country.service";
import Inputmask from 'inputmask';
import {NzTabsCanDeactivateFn} from 'ng-zorro-antd/tabs';
import {Observable} from 'rxjs';


export interface ModalData {
	user: User;
}

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});

  public user: User;

  public userForm: FormGroup;
  public timeTrackingForm: FormGroup;
  public passwordForm: FormGroup;
  public personalForm: FormGroup;

  public teams: Team[];
  public team: Team;
  public userLoading = true;
  public isTouched = false;
  public userId: string;
  public userRole: Roles = 'employee';

  public currentYear = moment().year();
  public years: number[] = [this.currentYear - 1, this.currentYear, this.currentYear + 1];

  public backendURL = environment.apiURL;

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    public authService: AuthenticationService,
    public countryService: CountryService,
  ) {
    this.route.queryParams.subscribe(params => {
      if (!params['tab']) {
        this.router.navigate(['.'], { relativeTo: this.route, queryParams: { tab: 'general' }, queryParamsHandling: 'merge', skipLocationChange: true});
      }
    })
    this.route.params.subscribe(params => {
        this.userId = params['id'] || null;
        if (!this.userId) {
          this.router.navigate(['users']);
        }

        if (this.userId === 'new') {
          this.user = {
            isActive: true,
            user_type: 'employee',
            prescribeBreakTime: true,
            emailNotification: false,
            default_ticket_contributor: false,
            yearlyVacationClaim: 0
          } as User;
          this.user.isAdmin = false;
          this.user.isManager = true;
          this.userLoading = false;
          this.initForm();
        } else {
          this.getUser();
        }
      });
    this.getTeams();
  }

  ngOnInit(): void {
  }

  public getUser(): void {
    this.api.getUsers(this.userId).subscribe((user: User) => {
      this.user = user;
      this.getRole();
      this.initForm();
      this.userLoading = false;
    }, onerror => {
      console.log(onerror);
      this.message.error('Manager konnte nicht geladen werden');
    });
  }

  private getRole(): void {
    if (this.user.isManager) {
      this.userRole = 'manager';
      if (this.user.isAdmin) {
        this.userRole = 'admin';
      }
    } else {
      this.userRole = 'employee';
    }
  }

  private initForm(): void {
    if (this.user?.username) {
      this.user.username = this.user.username.replace(/\s/g, '');
    }
    this.userForm = this.formBuilder.group({
      name: [this.user.name, [Validators.required, Validators.minLength(3)]],
      email: [this.user.email, [Validators.required, Validators.email]],
      username: [this.user.username, [Validators.required, Validators.minLength(5), this.noWhitespaceValidator]],
      phone: [this.user.phone],
      userTeams: [this.user.teams],
      userRole: [this.userRole],
      ticket_contributor: [this.user.default_ticket_contributor],
      emailNotification: [this.user.emailNotification],
    });

    this.personalForm = this.formBuilder.group({
      birthday: [this.user.personalFile?.birthday],
      address: [this.user.personalFile?.address],
      zip: [this.user.personalFile?.zip],
      city: [this.user.personalFile?.city],
      country: [this.user.personalFile?.country],
      nationality: [this.user.personalFile?.nationality],
      startDate: [this.user.personalFile?.startDate],
      endDate: [this.user.personalFile?.endDate],
      note: [this.user.personalFile?.note],
    })

    this.timeTrackingForm = this.formBuilder.group({
      prescribeBreakTime: [this.user.prescribeBreakTime],
      yearlyVacationClaim: [this.user.yearlyVacationClaim || 0, [Validators.min(0)]],
      // Formgroups
      vacationClaims: this.formBuilder.array([]),
    });

    if (this.user.vacationClaims) {
      for (let vacationClaim of this.user.vacationClaims) {
        // @ts-ignore
        this.timeTrackingForm.controls['vacationClaims'].push(this.formBuilder.group({
          id: vacationClaim.id,
          year: [vacationClaim.year, Validators.required],
          total: [vacationClaim.total, [Validators.required, Validators.min(0)]],
          remaining: [vacationClaim.remaining, [Validators.required, Validators.min(0)]],
          expiry: [vacationClaim.expiry, [Validators.required]],
          expired: [vacationClaim.expired],
          userId: [vacationClaim.userId, [Validators.required]]
        }));
      }
    }

    this.passwordForm = this.formBuilder.group({
      password: [this.user.password],
      passwordChangeMessage: [null]
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const hasWhitespace = (control && control.value && control.value.toString() || '').indexOf(' ') >= 0;
    return hasWhitespace ? {whitespace: {value: control.value}} : null;
  }

  public updateUser(): void {
    this.userLoading = true;
    const userFormData = this.userForm.getRawValue();
    const timeTrackingFormData = this.timeTrackingForm.getRawValue();
    const personalFileFormData = this.personalForm.getRawValue();
    this.user.name = userFormData.name.trim();
    this.user.email = userFormData.email.trim();
    this.user.phone = userFormData.phone;
    this.user.username = userFormData.username.trim();
    this.user.teams = userFormData.userTeams;
    this.user.default_ticket_contributor = userFormData.ticket_contributor;
    this.user.emailNotification = userFormData.emailNotification;

    this.user.prescribeBreakTime = timeTrackingFormData.prescribeBreakTime;
    this.user.yearlyVacationClaim = timeTrackingFormData.yearlyVacationClaim || 0;
    this.user.vacationClaims = timeTrackingFormData.vacationClaims;

    this.user.personalFile = personalFileFormData;

    this.userRole = userFormData.userRole;
    this.user.isManager = (this.userRole === 'manager' || this.userRole === 'admin');
    this.user.isAdmin = (this.userRole === 'admin');

    // VacationClaims
    userFormData.vacationClaims = [];
    for (let vc of this.vacationClaims.controls) {
      if (!vc.untouched) {
        userFormData.vacationClaims.push(vc.getRawValue());
      }
    }

    this.api.setUser(this.user).subscribe((user: User) => {
      this.message.success('Erfolgreich gespeichert');
      if (!this.user?.id) {
        this.notification.blank(
          'Neuer Mitarbeiter wurde angelegt',
          'Der Mitarbeiter wurde mit den persönlichen Zugangsdaten per E-Mail informiert. ' +
          'Sollte es Probleme mit der E-Mail-Adresse geben, generieren Sie bitte manuell ein Passwort.',
          {  }
        );
        this.router.navigate(['users/' + user.id]);
        this.userId = this.user.id;
        this.getUser();
      } else {
        this.getUser();
      }
      this.userLoading = false;
    }, error => {
      this.notification.create(
        'error',
        'Speichern fehlgeschlagen!',
        error.error?.error
      );
      this.userLoading = false;
    });
  }

  public getTeams(): void {
    this.api.getTeam().subscribe((teams: Team[]) => {
      this.teams = teams;
    }, onerror => {
      console.log(onerror);
      this.message.error('Teams konnten nicht geladen werden');
    });
  }

  public updatePassword(): void {
    const sendData = this.passwordForm.getRawValue();
    this.user.password = sendData.password;
    this.api.changePassword(sendData, this.user.id).subscribe((user: User) => {
      this.message.success('Passwort erfolgreich geändert.');
      this.passwordForm.reset();
      // User Benachrichtigung
      if (sendData.passwordChangeMessage) {
        const mailData = {
          'to': this.user.email,
          'subject': 'Ihr Passwort wurde geändert.',
          'text': 'Ihr neuse Passwort lautet: ' + sendData.password
        };
        this.api.sendMail(mailData).subscribe(() => {
          this.message.success('User erfolgreich benachrichtigt.');
        });
      }
    }, error => {
      this.message.error('Speichern fehlgeschlagen!');
    });
  }


  public passwordCancel(): void {
    this.modalService.warning({
      nzTitle: '<i>Passwort ändern?</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie dieses Passwort ändern möchten?',
      nzOnOk: () => this.updatePassword(),
      nzCancelText: 'Abbrechen',
    });
  }

  public disableUser(): void {
    this.modalService.warning({
      nzTitle: '<i>Benutzer deaktivieren</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie diesen Benutzer deaktivieren möchten?<br><br> Wenn ein Benutzer deaktiviert ist, wird der Zugang für den gewählten Benutzer' +
        '            gesperrt. Ein deaktivierter Benutzer wird bei zukünftigen Abrechnungen <strong>nicht</strong> mehr' +
        '            automatisch verlängert.',
      nzOnOk: () => {
        this.user.isActive = false;
        this.updateUser();
        this.writeActvityLog('DEACTIVATE_EMPLOYEE');
      },
      nzCancelText: 'Abbrechen'
    });
  }

  //Schreibt eine Log wenn ein Mitarbeiter Deaktiviert wird.
  public writeActvityLog(action: 'DEACTIVATE_EMPLOYEE' | 'ACTIVATE_EMPLOYEE'): void {
    const Log: ActivityLog = {
      activity: action,
      description: 'Der Aktivitätsstatus von "' + this.user.name + '" wurde geändert',
      user_id: this.userId
    } as ActivityLog
    this.api.writeActivityLog(Log).subscribe(value => {});
  }


  public enableUser(): void {
    this.modalService.success({
      nzTitle: '<i>Benutzer reaktivieren</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie diesen Benutzer reaktivieren möchten? Die Abrechnung für diesen Benutzer wird dadurch fortgesetzt. Es entstehen Kosten, falls der Benutzer für den laufenden Zeitraum noch nicht abgerechnet wurde!',
      nzOnOk: () => {
        this.user.isActive = true;
        this.updateUser();
        this.writeActvityLog('ACTIVATE_EMPLOYEE');
      },
      nzCancelText: 'Abbrechen'
    });
  }

  public editRenewInterval(): void {
    this.modalService.create<EditUserBillingIntervalComponent, EditUserBillingIntervalModalData>({
      nzContent: EditUserBillingIntervalComponent,
      nzData: {
        user: this.user
      },
      nzWidth: 800,
      nzClosable: false,
      nzFooter: null,
      nzOnOk: instance => {
        this.getUser();
      }
    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  public deleteAvatar(): void {
    this.modalService.warning({
      nzTitle: "<i>Profilbild löschen?</i>",
      nzContent:
          "Sind Sie sich sicher, dass Sie das Profilbild löschen möchten?",
      nzOnOk: () => this.delete(),
      nzCancelText: "Abbrechen",
    });
  }

  public delete(): void {
    this.api.deleteAvatar(this.user.id).subscribe(value => {
      console.log(value);
      this.getUser();
    });
  }

  public onChange(event: any): void {
    console.log(event);
    if (event.type === 'success') {
      this.message.success('Profilbild wurde hochgeladen/geändert')
      this.getUser();
    } else if (event.type === 'error') {
      this.message.error(event.file.error.error);
      this.getUser();
    }
  }

  get vacationClaims() {
    return this.timeTrackingForm.controls['vacationClaims'] as FormArray;
  }

  public setUsername(): void {
    if (!this.userForm.get('username').value) {
      this.userForm.get('username').setValue(this.userForm.get('email').value);
    }
  }

  public calcVacationClaim(year: number) {
    this.api.calcVacationClaim({userId: this.user.id, year}).subscribe((result: VacationClaim) => {
      this.user.vacationClaims.push(result);
      this.initForm();
    }, error => {
      this.message.error(error.error?.error || 'Urlaubsanspruch konnte nicht erzeugt werden!');
    });
  }

  public vacationClaimYearExists(year: number): boolean {
    return !!this.vacationClaims.controls.find(vc => vc.get('year')?.value === year);
  }

  public commaToPoint(event) {
    setTimeout(() => {
      let vc = this.timeTrackingForm.get('yearlyVacationClaim').value;

      if (vc) {
        let formattedValue = vc.toString().replace(',', '.'); // Ersetze , mit .

        // Prüfe, ob es eine gültige Zahl ist
        let num = parseFloat(formattedValue);

        if (!isNaN(num)) {
          let decimalPart = (num * 10) % 10; // Hol die Nachkommastelle

          if (decimalPart === 0 || decimalPart === 5) {
            this.timeTrackingForm.get('yearlyVacationClaim').setValue(formattedValue);
          } else {
            this.timeTrackingForm.get('yearlyVacationClaim').setValue(Math.floor(num) + ',0'); // Setze Standardwert
          }
        }
      }
    }, 100);
  }

  public canDeactivate: NzTabsCanDeactivateFn = (fromIndex: number, toIndex: number) => {
    console.log(toIndex, fromIndex)
    switch (fromIndex) {
      case 0:
        if (this.userForm.touched) {
          return this.confirm('general');
        }
      case 1:
        if (this.personalForm.touched) {
          return this.confirm('personalFile');
        }
      case 2:
        if (this.timeTrackingForm.touched) {
          return this.confirm('timetracking');
        }
      default:
        this.getUser();
        return true;
    }
  }

  private confirm(tab: string): Observable<boolean> {
    return new Observable(observer => {
      this.modalService.confirm({
        nzTitle: 'Ohne speichern wechseln? Alle Änderungen gehen verloren.',
        nzOnOk: () => {
          observer.next(true);
          observer.complete();
        },
        nzOnCancel: () => {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { ['tab']: tab },
            queryParamsHandling: 'merge', // Keeps existing query params
          });
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  protected readonly moment = moment;
  protected readonly momentPlugin = momentTimezone;
}

import {Component, inject, Input, OnInit, ViewChild} from '@angular/core';
import {Counter, CounterStatus, File, Object} from "../../../../../../../database-models";
import {ApiService} from "../../../../services/api.service";
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {NzMessageService} from "ng-zorro-antd/message";
import {FormBuilder} from "@angular/forms";
import {AuthenticationService} from "../../../services/authentication.service";
import {environment} from "../../../../../environments/environment";
import {DatePipe} from "@angular/common";
import {saveAs} from 'file-saver';
import moment from 'moment';
import {NzTableComponent} from 'ng-zorro-antd/table';
import { ExportService } from 'src/app/shared/services/export.service';

export interface ModalData {
  counter: Counter;
}

@Component({
  selector: 'app-counter-status-historie-list',
  templateUrl: './counter-status-historie-list.component.html',
  styleUrls: ['./counter-status-historie-list.component.css']
})
export class CounterStatusHistorieListComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @Input() public counter: Counter;
  public userId: string;
  public counterStatuses: CounterStatus[];
  public isLoading = false;
  public backendURL = environment.apiURL;

  public checked = false;
  public indeterminate = false;
  public listOfCurrentPageData: CounterStatus[] = [];
  public setOfCheckedId = new Set<string>();

  constructor(
      public api: ApiService,
      private modal: NzModalRef,
      private message: NzMessageService,
      public formBuilder: FormBuilder,
      private modalService: NzModalService,
      private authenticationService: AuthenticationService,
      private datePipe: DatePipe,
      private exportService: ExportService
  ) {
  }

  ngOnInit(): void {
    this.counter ??= this.data.counter;
    this.getCounterStatuses();
    this.userId = this.authenticationService.currentUserValue.id
  }

  public getCounterStatuses(): void {
    this.isLoading = true;
    this.api.getCounterStatusbyCounter(this.counter.id).subscribe(value => {
      this.counterStatuses = value;
      this.isLoading = false;
    })
  }

  public deleteCounterStatus(statusId: string, index: number): void {
    this.modalService.warning({
      nzTitle: 'Zählerstand löschen',
      nzContent: 'Sind Sie sicher, dass Sie den Zählerstand löschen möchten?',
      nzOnOk: () => this.delete(statusId, index),
      nzCancelText: 'Abbrechen'
    });
  }

  public delete(statusId: string, index: number): void {
    this.api.deleteCounterStatus(statusId).subscribe(value => {
      this.counterStatuses.splice(index, 1)
      this.message.info('Zählerstand wurde gelöscht');
    })
  }


  public onChange(event: any): void {
    console.log(event);
    if (event.type === 'success') {
      this.message.success('Bild wurde hochgeladen')
      this.getCounterStatuses();
    } else if (event.type === 'error') {
      this.message.error(event.file.message);
      this.getCounterStatuses();
    }
  }

  public onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData.forEach(({id}) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  public updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  public refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(({id}) => this.setOfCheckedId.has(id));
    this.indeterminate = this.listOfCurrentPageData.some(({id}) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  public onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  public onCurrentPageDataChange(listOfCurrentPageData: CounterStatus[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  public async exportReport(withImg: boolean): Promise<void> {
    const msgid = this.message.loading('PDF wird erzeugt ...', { nzDuration: 0 }).messageId;
    this.api.downloadCountersPDF([{
      id: this.counter.id,
      counterStatuses: [...this.setOfCheckedId]
    }], withImg).subscribe((res) => {
      saveAs(res, this.counter.counterNumber);
      this.message.remove(msgid);
    }, error => {
      this.message.remove(msgid);
      this.message.error('Zähler konnten nicht exportiert werden')
    });
  }

  protected readonly moment = moment;
}

<div *minAppversion="3">
  <nz-spin [nzSpinning]="loading">
    <h2>E-Mail Senden</h2>
    <form nz-form nzLayout="vertical" [formGroup]="mailForm">

      <nz-form-item>
        <nz-form-label nzFor="to" nzRequired>An</nz-form-label>
        <nz-form-control>
          <input formControlName="to" nz-input placeholder="An"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="Cc">Cc</nz-form-label>
        <nz-form-control>
          <input formControlName="cc" nz-input placeholder="Cc"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="subject" nzRequired>Betreff</nz-form-label>
        <nz-form-control>
          <input formControlName="subject" nz-input placeholder="Betreff"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="m-b-5">
        <nz-form-label nzFor="text" nzRequired>Text</nz-form-label>
        <nz-form-control>
          <quill-editor class="w-100" formControlName="text" [styles]="{height: '150px'}"
                        placeholder=""></quill-editor>
        </nz-form-control>
      </nz-form-item>
      <ng-container *ngIf="attachmentLabel">
        <p class="w-100">
          <i nz-icon nzType="paper-clip" nzTheme="outline"></i> {{ attachmentLabel }}
        </p>
      </ng-container>

    </form>
    <div class="text-right">
      <button class="button m-h-10" nz-button nzType="default" (click)="cancel()" type="button">Abbrechen</button>
      <button class="button" nz-button nzType="primary" type="submit" (click)="send()" [disabled]="mailForm.invalid">
        Senden
      </button>
    </div>
  </nz-spin>
</div>

import {Component, inject, Input, OnInit} from '@angular/core';
import {MailTemplate, SuperAdminMail} from '../../../../../../database-models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SuperadminApiService} from '../../../super-admin/services/superadmin-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export interface ModalData {
	mailTemplateID: string;
  to: string;
  variables?: any;
}

@Component({
  selector: 'app-send-mail-editor',
  templateUrl: './send-mail-editor.component.html',
  styleUrls: ['./send-mail-editor.component.css']
})
export class SendMailEditorComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public loading = false;
  public mailTemplates: MailTemplate[];

  public mailTemplate: MailTemplate;

  @Input()
  public mailTemplateID: string;

  @Input()
  public variables: any;

  @Input()
  public to: string;

  public mailForm: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public api: SuperadminApiService,
    public route: ActivatedRoute,
    public router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private modal: NzModalRef
  ) {
    this.getTemplates();
  }

  public ngOnInit(): void {
    this.mailTemplateID ??= this.data.mailTemplateID;
    this.variables ??= this.data.variables;
    this.to ??= this.data.to;

    this.mailTemplate = {} as MailTemplate;
    this.initForm();
  }

  private initForm(): void {
    this.mailForm = this.formBuilder.group({
      to: [this.to, [Validators.required]],
      summary: ['', [Validators.required]],
      text: ['', [Validators.required]]
    });
  }

  public send(): void {
    const sendData = this.mailForm.getRawValue();

    const mail = {
      to: sendData.to,
      summary: sendData.summary,
      text: sendData.text,
      company_id: this.variables?.company_id,
      property_management_id: this.variables?.property_management_id,
      mailtemplate_id: this.mailTemplate.id
    } as SuperAdminMail;

    this.api.sendMail(mail).subscribe((res: any) => {
      this.message.success('Erfolgreich gesendet');
      this.modal.triggerOk();
    }, error => {
      this.notification.create(
        'error',
        'Senden fehlgeschlagen!',
        error.error
      );
    });
  }

  public cancel(): void {
    if (this.mailForm.touched) {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent: 'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => this.modal.triggerCancel()
      });
    } else {
      this.modal.triggerCancel();
    }
  }

  public getTemplates(): void {
    this.loading = true;
    this.api.getMailTemplate().subscribe((templates: MailTemplate[]) => {
      this.mailTemplates = templates;
      if (this.mailTemplateID) {
        this.mailTemplate = this.mailTemplates.find(value => {
          return value.id === this.mailTemplateID;
        });
      }
      if (this.mailTemplate) {
        this.setTemplate();
      }
      this.loading = false;
    }, onerror => {
      this.loading = false;
      console.log(onerror);
    });
  }

  public setTemplate(): void {
    if (this.mailTemplate) {
      let text = this.mailTemplate.text;
      if (text) {
        for (const key in this.variables) {
          text = text.replace('{{' + key + '}}', this.variables[key]);
        }
      }

      this.mailForm.get('text').setValue(text);
      this.mailForm.get('summary').setValue(this.mailTemplate.summary);
    }
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

}

<nz-table #basicTable [nzData]="['']" nzSize="small">
  <thead>
  <tr>
    <th>Karte</th>
    <th>Zeitpunkt</th>
    <th>Latitude / Longitude</th>
    <th>Löschen</th>

  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of worklog?.geolocations; let i = index">
    <td style="width: 500px;">
      <iframe
        width="450"
        height="250"
        style="border:0"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        [src]="'https://www.google.com/maps/embed/v1/place?key=' + environment.googleApiKey + '&q=' + data.latitude + '%2C' + data.longitude | safe:'resourceUrl'">
      </iframe>
    </td>
    <td style="width: 300px;">{{ data.createdAt | date: 'dd.MM.yyyy HH.mm' }} Uhr</td>
    <td>{{ data.latitude }}<br>{{ data.longitude }}</td>
    <td>
    <button *ngIf="!worklog.finalized" (click)="deleteGPS(i, data); $event.stopPropagation()"
            nzSize="small"
            nz-button nzType="link"
    ><i nz-icon nzType="delete"></i></button>
    </td>
  </tr>

  </tbody>
</nz-table>

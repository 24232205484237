import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeoLocation, WorkLog} from "../../../../../../database-models";
import {environment} from '../../../../environments/environment';
import {NzModalService} from "ng-zorro-antd/modal";
import {ApiService} from "../../../services/api.service";
import {MessageService} from "../../services/message.service";

@Component({
  selector: 'app-gps-list',
  templateUrl: './gps-list.component.html',
  styleUrls: ['./gps-list.component.css']
})
export class GpsListComponent implements OnInit {

  public mapsUrl = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyAByxN6OqklAULsFBuDxu4r1rXwOAv0BKA&q='

  @Input() public worklog: WorkLog;

  constructor(
    private modalService: NzModalService,
    public api: ApiService,
    public message: MessageService

  ) { }

  ngOnInit(): void {
  }

  public deleteGPS(i: number, geolocation: GeoLocation) {
    this.modalService.warning({
      nzTitle: 'GPS-Stempel löschen?',
      nzContent: 'Sind Sie sich sicher, dass Sie den GPS-Stempel löschen möchten?',
      nzOnOk: () => {
        this.api.deleteWorklogGeolocation(geolocation.id).subscribe(res => {
          this.worklog.geolocations.splice(i, 1);
          this.message.success('Der Eintrag wurde erfolgreich gelöscht.')
        }, error => {
          this.message.error('Eintrag konnte nicht gelöscht werden.');
        });
      },
      nzCancelText: 'Abbrechen',
    });
  }

  protected readonly environment = environment;
}

import {Component, OnInit} from '@angular/core';
import {Contact, Object, Tag, User, WorkLog, WorklogBulUpdate} from '../../../../../../database-models';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {DatePipe} from '@angular/common';
import moment from 'moment';
import {HttpParams} from '@angular/common/http';
import {EditWorklogComponent, ModalData as EditWorklogModalData} from '../edit-worklog/edit-worklog.component';
import {ExportService} from '../../services/export.service';
import {NewWorklogComponent} from '../new-worklog/new-worklog.component';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {WorklogService} from '../../services/worklog.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {environment} from '../../../../environments/environment';
import {AuthenticationService} from '../../services/authentication.service';
import {LexofficeService} from '../../services/lexoffice.service';

@Component({
  selector: 'app-worklog-list',
  templateUrl: './worklog-list.component.html',
  styleUrls: ['./worklog-list.component.css'],
  providers: [DatePipe]
})
export class WorklogListComponent implements OnInit {

  public objects: Object[];
  public users: User[];
  public worklogs: WorkLog[];
  public contacts: Contact;

  public checked = false;
  public indeterminate = false;
  public listOfCurrentPageData: WorkLog[] = [];
  public setOfCheckedId = new Set<string>();

  public modal: NzModalRef;
  public availableTags: Tag[];
  public hideLockedUsers = true;

  public nameSearch: string;

  public worklogsLoading = true;
  public showFilter = false;

  public tableState: any = {
    total: 0,
    pageIndex: 1,
    pageSize: 20,
  };

  public filter = {
    show: false,
    linkedTicket: false,
    reference: [],
    user: null,
    billed: true,
    search: null,
    dateRange: [],
    tags: [],
  };

  public sortOrders = {
    details: null,
    task: null,
    time: null
  };

  public constructor(
    private api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    private modalService: NzModalService,
    public authenticationService: AuthenticationService,
    private message: NzMessageService,
    public exportService: ExportService,
    public lexofficeService: LexofficeService,
    private datePipe: DatePipe,
    private worklogService: WorklogService
  ) {
    const storageFilter = localStorage.getItem('worklogListFilter');
    if (storageFilter) {
      this.filter = JSON.parse(storageFilter);
    }

    /*query from object details*/
    this.route.queryParams.subscribe(value => {
      if (value.objectId) {
        this.api.getObjects(value.objectId).subscribe(obj => {
          this.filter.reference = [obj];
          this.filter.show = true;
          this.getWorklogs();
        });
      } else {

      }
      this.getUsers();
    });
  }

  public ngOnInit(): void {
    const storedOrderFn = localStorage.getItem('tableheaderWorklog');
    if (storedOrderFn) {
      this.sortOrders = JSON.parse(storedOrderFn);
    }
    this.api.getCompanyTags().subscribe(tags => this.availableTags = tags.filter(tag => tag.entity.includes('worklog')));
  }

  public sortChanged(change: any) {
    localStorage.setItem('tableheaderWorklog', JSON.stringify(this.sortOrders));
  }

  public getWorklogs(queryParams?: NzTableQueryParams, resetSkip: boolean = false): void {

    this.setOfCheckedId.clear();

    this.worklogsLoading = true;
    let httpParams = new HttpParams();
    if (this.filter.reference) {
      let objectIds = [];
      let customerIds = [];
      try {
        for (let ref of this.filter.reference) {
          if (ref.member === 'Object') {
            objectIds.push(ref.id);
          }
          if (ref.member === 'Contact') {
            customerIds.push(ref.id);
          }
        }
      } catch (e) {
        this.filter.reference = [];
      }
      httpParams = httpParams.append('objectIds', JSON.stringify(objectIds));
      httpParams = httpParams.append('customerIds', JSON.stringify(customerIds));
    }

    if (this.filter.dateRange && this.filter.dateRange[0] && this.filter.dateRange[1]) {
      const start = moment(this.filter.dateRange[0]).startOf('day').toISOString();
      const end = moment(this.filter.dateRange[1]).endOf('day').toISOString();
      httpParams = httpParams.append('start', start);
      httpParams = httpParams.append('end', end);

    }


    if (this.filter.user) {
      httpParams = httpParams.append('employeeId', this.filter.user.id);
    }
    if (this.filter.tags) {
      httpParams = httpParams.append('tagIds', JSON.stringify(this.filter.tags.map(tag => tag.id)));
    }
    if (this.filter.billed) {
      httpParams = httpParams.append('billed', 'true');
    }
    if (this.filter.linkedTicket) {
      httpParams = httpParams.append('linkedTicket', 'true');
    }

    if (this.filter.search) {
      httpParams = httpParams.append('search', this.filter.search);
    }

    if (resetSkip) {
      this.tableState.pageIndex = 1;
    }
    httpParams = httpParams.append('pageIndex', this.tableState.pageIndex - 1);


    httpParams = httpParams.append('pageSize', this.tableState.pageSize);

    if (queryParams) {
      httpParams = httpParams.append('sort', JSON.stringify(queryParams.sort));
    }


    this.api.getWorklogs(httpParams).subscribe((worklogs: any) => {
      this.tableState.total = worklogs.count;
      this.worklogs = worklogs.rows;
      this.worklogsLoading = false;



    }, onerror => {
      console.log(onerror);
      this.worklogsLoading = false;
      this.message.error('Arbeitsprotokolle konnten nicht geladen werden!');
    });

    localStorage.setItem('worklogListFilter', JSON.stringify(this.filter));
  }

  public editWorklog(worklog: WorkLog): void {
    this.modal = this.modalService.create<EditWorklogComponent, EditWorklogModalData>({
      nzContent: EditWorklogComponent,
      nzData: {
        worklogId: worklog.id
      },
      nzWidth: 1450,
      nzFooter: null,
      nzKeyboard: false
    });

    this.modal.afterClose.subscribe((data: any) => {
      this.getWorklogs();
    });
  }

  public newWorklog(): void {
    this.worklogService.newWorklog().then(res => {
      this.getWorklogs();
    });
  }

  public getUsers(): void {
    this.api.getAllUsers().subscribe((employees: User[]) => {
        this.users = employees;
      }, onerror => {
        console.log(onerror);
        this.message.error('Mitarbeiter konnten nicht geladen werden');
      }
    );
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  public getDuration(worklog: WorkLog | any): any {
    const end = moment(new Date(worklog.end));
    const start = moment(new Date(worklog.start));
    return moment.duration(end.diff(start));
  }

  public export(type: 'excel' | 'csv' | 'pdf'): void {
    const exportJSON: any[] = [];
    const selectedWorklogs = [];
    for (let id of this.setOfCheckedId) {
      let item = this.worklogs.find(wl => wl.id === id);
      selectedWorklogs.push(item);
    }
    for (const wl of selectedWorklogs) {
      const row = {
        Start: this.datePipe.transform(wl.start, 'dd.MM.y H:mm'),
        Ende: this.datePipe.transform(wl.end, 'dd.MM.y H:mm'),
        'Objekt/Kunde': wl.object ? (wl.object.name + (wl.room ? ' (' + wl.room.name + ')' : '')) : (wl.contact?.name),
        Taetigkeit: wl.description,
        Ersteller: wl.creator?.name || '',
        Mitarbeiter: wl.involved_users?.map(usr => ' ' + usr.name),
        Tage: wl.end ? this.getDuration(wl).days() : '',
        Stunden: wl.end ? this.getDuration(wl).hours() : '',
        Minuten: wl.end ? this.getDuration(wl).minutes() : '',
        Industriezeit: wl.end ? (moment(wl.end).diff(wl.start, 'minutes') / 60).toFixed(2) : 0,
        Ticket: wl.ticket ? wl.ticket.number : '',
        Aufgabe: wl.task ? wl.task.title : '',
        Finalisiert: this.datePipe.transform(wl.finalized, 'dd.MM.y H:mm'),
        Material: function () {
          let returnString = '';
          let index = 1;
          for (const con of wl.consumed) {
            returnString += con.Consumable_Ticket.number + ' ' + con.unit + ' ' + con.name;
            if (index < wl.consumed.length) {
              returnString += '\n';
            }
            index++;
          }
          return returnString;
        }(),
        'Erbrachte Leistungen': function () {
          let returnString = '';
          if (wl.completedServices && Array.isArray(wl.completedServices)) {
            let index = 1;
            for (const item of wl.completedServices) {
              returnString += item.name;
              if (index < wl.completedServices.length) {
                returnString += '\n';
              }
              index++;
            }
          }
          return returnString;
        }()
      };


      exportJSON.push(row);
    }

    switch (type) {
      case 'excel':
        this.exportService.exportJsonToExcel(exportJSON, 'Arbeitsprotokoll');
        break;
      case 'csv':
        this.exportService.exportToCsv(exportJSON, 'Arbeitsprotokoll');
        break;
      case 'pdf':
        this.exportService.exportToPDF(exportJSON, 'Arbeitsprotokolle');
        break;
    }


  }

  public exportReport(): void {
    const exportJSON: any[] = [];
    const selectedWorklogs = [];
    for (let id of this.setOfCheckedId) {
      let item = this.worklogs.find(wl => wl.id === id);
      selectedWorklogs.push(item);
    }
    for (const wl of selectedWorklogs) {
      const row = {
        'Datum': this.datePipe.transform(wl.start, 'dd.MM.YY'),
        'Start / Ende': {
          content: this.datePipe.transform(wl.start, 'dd.MM.YY HH:mm') + '\n' + (wl.end ? (this.datePipe.transform(wl.end, 'dd.MM.YY HH:mm')) : '-'),
          styles: {
            fontSize: 8
          }
        },
        'Objekt/Kunde': wl.object ? (wl.object.name + (wl.room ? ' (' + wl.room.name + ')' : '')) : (wl.contact?.name),
        Mitarbeiter: (wl.creator ? wl.creator.name + ',' : '') + (wl.involved_users.map(usr => ' ' + usr.name)),
        Taetigkeit: wl.description,


        'Erbrachte Leistungen': {
          content: function () {
            let returnString = '';
            if (wl.completedServices && Array.isArray(wl.completedServices)) {
              let index = 1;
              for (const item of wl.completedServices) {
                returnString += item.name;
                if (index < wl.completedServices.length) {
                  returnString += ',\n';
                }
                index++;
              }
            }
            return returnString;
          }(),
          styles: {
            fontSize: 8
          }
        },

        'Material': {
          content: function () {
            let returnString = '';
            if (wl.consumed && Array.isArray(wl.consumed)) {
              let index = 1;
              for (const con of wl.consumed) {
                returnString += con.Consumable_Ticket.number + con.unit + ' ' + con.name;
                if (index < wl.consumed.length) {
                  returnString += ',\n';
                }
                index++;
              }
            }
            return returnString;
          }(),
          styles: {
            fontSize: 8
          }
        }
      };


      exportJSON.push(row);
    }

    var doc = new jsPDF();

    doc.text('Arbeitsprotokolle', 15, 10);


    autoTable(doc, {
      head: [Object.keys(exportJSON[0])],
      body: exportJSON.map(obj => Object.values(obj)),
      headStyles: {
        fillColor: [255, 143, 0]
      },
      columnStyles: {
        /*				0: { cellWidth:"auto" },
                1: { cellWidth:"auto" },
                2: { cellWidth:"auto" },
                3: { cellWidth:"auto" },
                4: { cellWidth: 30 },
                5: { cellWidth: 20 },
                6: { cellWidth: 20 }*/
      }
    });

    doc.save('Arbeitsprotokolle' + '.pdf');


  }

  public setRange(period: 'today' | 'tw' | 'lw' | 'tm' | 'lm' | 'tj' | 'lj'): void {
    switch (period) {
      case 'today':
        this.filter.dateRange = [moment().startOf('day').toISOString(), moment().endOf('day').toISOString()];
        break;
      case 'tw':
        this.filter.dateRange = [moment().startOf('week').toISOString(), moment().endOf('week').toISOString()];
        break;
      case 'lw':
        this.filter.dateRange = [moment().subtract(1, 'week').startOf('week').toISOString(), moment().subtract(1, 'week').endOf('week').toISOString()];
        break;
      case 'tm':
        this.filter.dateRange = [moment().startOf('month').toISOString(), moment().endOf('month').toISOString()];
        break;
      case 'lm':
        this.filter.dateRange = [moment().subtract(1, 'month').startOf('month').toISOString(), moment().subtract(1, 'month').endOf('month').toISOString()];
        break;
      case 'tj':
        this.filter.dateRange = [moment().startOf('year').toISOString(), moment().endOf('year').toISOString()];
        break;
      case 'lj':
        this.filter.dateRange = [moment().subtract(1, 'year').startOf('year').toISOString(), moment().subtract(1, 'year').endOf('year').toISOString()];
        break;
    }
  }

  public timeIsPast(): boolean {
    return (this.filter?.dateRange && this.filter?.dateRange[1]) ? (moment(this.filter.dateRange[1]) < moment()) : false;
  }

  public searchFilter() {

  }

  public onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData.forEach(({id}) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  public updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  public refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(({id}) => this.setOfCheckedId.has(id));
    this.indeterminate = this.listOfCurrentPageData.some(({id}) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  public onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  public onCurrentPageDataChange(listOfCurrentPageData: WorkLog[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  public isFinalizedInArray(): boolean {
    for (let id of this.setOfCheckedId) {
      const worklog = this.worklogs.find(wl => wl.id === id);
      if (worklog?.finalized) {
        return true;
      }
    }
    return false;
  }

  public bulkUpdateFinalized(): void {
    this.modalService.confirm({
      nzTitle: '<i>Finalisierung aller ' + this.setOfCheckedId.size + ' Arbeitsprotokolle?</i>',
      nzContent: 'Sind Sie sicher, dass Sie die Finalisierung dieser Arbeitsprotokolle vornehmen möchten? Dies ist nicht mehr änderbar.',
      nzOnOk: () => {
        const bulk: WorklogBulUpdate = {
          ids: Array.from(this.setOfCheckedId),
          field: 'finalized',
          value: new Date().toISOString()
        } as WorklogBulUpdate;

        this.api.updateWorkLogBulk(bulk).subscribe(res => {
          this.message.success('Das Update war erfolgreich!');
          this.getWorklogs();
          this.setOfCheckedId.clear();
        }, error => {
          this.message.error('Das Update war nicht erfolgreich!');
        });
      }
    });
  }

  public bulkUpdateBilled(value: any): void {
    this.modalService.confirm({
      nzTitle: '<i>Abrechnung aller ' + this.setOfCheckedId.size + ' Arbeitsprotokolle ändern</i>',
      nzContent: 'Sind Sie sicher, dass Sie die Abrechnung dieser Arbeitsprotokolle vornehmen möchten?',
      nzOnOk: () => {
        const bulk: WorklogBulUpdate = {
          ids: Array.from(this.setOfCheckedId),
          field: 'billed',
          value: value
        } as WorklogBulUpdate;

        this.api.updateWorkLogBulk(bulk).subscribe(res => {
          this.message.success('Das Update war erfolgreich!');
          this.getWorklogs();
          this.setOfCheckedId.clear();
        }, error => {
          this.message.error('Das Update war nicht erfolgreich!');
        });
      }
    });
  }

  public orderFn = {
    details: (a, b) => a.object?.name?.localeCompare(b.object?.name) || a.contact?.name?.localeCompare(b.contact?.name),
    task: (a, b) => a.description?.localeCompare(b.description),
    time: (a, b) => moment(a.start).valueOf() - moment(b.start).valueOf()
  };

  public convertTime(milliseconds: number): string {
    if (!milliseconds) {
      return null;
    }

    let seconds = milliseconds / 1000;

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - (hours * 3600)) / 60);
    seconds = Math.floor(seconds - (hours * 3600) - (minutes * 60));

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

  public openTab(link: string) {
    window.open(link, '_blank');
  }

  public toogleFilter(): void {
    this.filter.show = !this.filter.show;
    this.getWorklogs();
  }

  public resetFilter(): void {
    this.filter = {
      show: true,
      linkedTicket: false,
      reference: [],
      user: null,
      billed: true,
      search: null,
      dateRange: [],
      tags: [],
    };
    localStorage.setItem('worklogListFilter', JSON.stringify(this.filter));
    this.router.navigate(['/worklogs/']); /*Pfad zu Reseten wenn man von Object Details kommt*/
    this.getWorklogs();
    this.message.success('Filter wurde zurückgesetzt');
  }

  public checkForActiveFilters(): number {
    let active = 0;
    for (let key in this.filter) {
      if (key !== 'show') {
        if (typeof this.filter[key] === 'boolean') {
          if ( key === 'linkedTicket') {
            if (this.filter[key]) {
              active++;
            }
          } else if (key === 'billed') {
            if (!this.filter[key]) {
              active++;
            }
          } else {
            if (this.filter[key] === true || this.filter[key] === false) {
              active++;
            }
          }
        } else if (Array.isArray(this.filter[key])) {
          if (this.filter[key].length > 0) {
            active++;
          }
        } else {
          if (this.filter[key]) {
            active++;
          }
        }
      }
    }
    return active;
  }

  public sendToLexOffice(): void {
    const selectedWorklogs = [];
    for (let id of this.setOfCheckedId) {
      let item = this.worklogs.find(wl => wl.id === id);
      selectedWorklogs.push(item);
    }

    this.lexofficeService.worklogToInvoice(selectedWorklogs).then(lexInvoice => {
      this.getWorklogs();
    }, reason => {

    })
  }

  timeout = null;

  public triggerSearch(skipTimeout: boolean = false): void {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.getWorklogs(null, true);
    }, skipTimeout ? 0 : 1000);
  }

  protected readonly moment = moment;
    protected readonly environment = environment;
}

<div nz-popover [nzPopoverContent]="contentTemplate" (nzPopoverVisibleChange)="getTask()"
     nzPopoverTrigger="hover" (click)="editTask(task)" class="task-tag task-done" [ngClass]="{'task-done' : (!task.recurring && task.recurringDone?.length > 0)}" *ngIf="task">
  <span class="user" [style.background-color]="task.color">
    <ng-container *ngIf="task.backlog">
      <strong>BACKLOG</strong> <br> <small>Zeit nicht festgelegt</small>
    </ng-container>
      <ng-container *ngIf="!task.backlog">
       <strong> {{ task.start | date:'dd.MM.y' }}</strong>
       <br>
        {{ task.start | date:'HH:mm' }} - {{ task.end | date:'HH:mm' }}
        <ng-container
          *ngIf="moment(task.end).diff(task.start, 'day') > 0">(+{{ moment(task.end).diff(task.start, 'day') }}
          Tag)</ng-container>
    </ng-container>
  </span>
  <span class="info">
    <i *ngIf="(!task.recurring && task.recurringDone?.length > 0)" class="checked-task color-green" nz-icon nzType="check-circle" nzTheme="fill"></i>
    <span class="title">

    {{ task.title }}</span> <br>
    <div *ngIf="task.users?.length > 0">
  <ng-container *ngFor="let user of task.users">
    <app-username-avatar [imageOnly]="task.users.length > 1" [user]="user"></app-username-avatar>
  </ng-container>

</div>
<div *ngIf="task.team">
  Team: {{ task.team.name }}
</div>

<ng-template #contentTemplate>
  <app-task-overview [(task)]="task"></app-task-overview>
</ng-template>


import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';

export interface ModalData {
  subject: string;
  to: string;
  cc?: string;
  variables: any;
  text: string;
  attachmentLabel?: string;
}

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss']
})
export class SendEmailDialogComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, { optional: true });
  public mailForm: FormGroup;
  public loading = false;

  @Input()
  public subject: string;

  @Input()
  public to: string;

  @Input()
  public cc?: string;

  @Input()
  public text: string;

  @Input()
  public variables: any;

  @Input()
  public attachmentLabel?: string;

  constructor(
    public formBuilder: FormBuilder,
    private api: ApiService,
    private message: NzMessageService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private modal: NzModalRef
  ) { }

  public ngOnInit(): void {
    this.subject ??= this.data.subject;
    this.text ??= this.data.text;
    this.variables ??= this.data.variables;
    this.to ??= this.data.to;
    this.cc ??= this.data.cc;
    this.attachmentLabel ??= this.data.attachmentLabel;

    this.mailForm = this.formBuilder.group({
      to: [this.to, [Validators.required, Validators.email]],
      cc: [this.cc, [Validators.email]],
      subject: [this.subject, [Validators.required]],
      text: [this.text, [Validators.required]]
    });
  }

  public send(): void {
    this.loading = true;
    const { to, cc, text, subject } = this.mailForm.getRawValue();

    const mail = {
      to,
      cc,
      subject,
      text,
      worklog_id: this.variables.worklog_id,
    };

    this.api.sendWorklogMail(mail).subscribe((res: any) => {
      this.message.success('Erfolgreich gesendet');
      this.modal.triggerOk();
    }, error => {
      this.loading = false;
      this.notification.create(
        'error',
        'Senden fehlgeschlagen!',
        error.error
      );
    });
  }

  public cancel(): void {
    if (this.mailForm.touched) {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent: 'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => this.modal.triggerCancel()
      });
    } else {
      this.modal.triggerCancel();
    }
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

}
